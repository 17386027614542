import { useState, useContext } from "react";
import { Link } from "react-router-dom";

// HOOKS
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/browser";

// CONTEXT
import { FirebaseContext } from "@/firebase";

//CONSTANTS
import * as ROUTES from "../../routes/APP_ROUTES";
import { LoadTermsAndConditions } from "@/components/ui/utilities/LoadTermsAndConditions";

// COMPONENTS
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
import { Form } from "@/components/ui/auth/formLayouts/FormLayouts";

import {
  Label,
  Input,
  InputPassword,
  ErrorMessage,
} from "@/components/ui/auth/formLayouts/FormLayouts";

import * as APP_ROUTES from "@/routes/APP_ROUTES";
import { useSelector } from "react-redux";

// SCHEMA
export const registerSchema = z
  .object({
    name: z.string().trim().min(3, {
      message: "Por favor ingresa tu nombre",
    }),
    lastName: z.string().trim().min(3, {
      message: "Por favor ingresa tu Apellido",
    }),
    email: z.string().trim().email({
      message: "Por favor ingresa un correo válido",
    }),
    password: z.string().min(8, {
      message: "La contraseña debe tener como mínimo 8 carácteres.",
    }),
    terms: z.boolean(),
  })
  .refine((data) => data.terms, {
    path: ["terms"],
    message: "Debes aceptar los términos y condiciones",
  })
  .superRefine(({ password }, checkPassComplexity) => {
    const containsUppercase = (ch) => /[A-Z]/.test(ch);
    const containsLowercase = (ch) => /[a-z]/.test(ch);
    let countOfUpperCase = 0,
      countOfLowerCase = 0,
      countOfNumbers = 0;

    for (let i = 0; i < password.length; i++) {
      let ch = password.charAt(i);
      if (!isNaN(+ch)) countOfNumbers++;
      else if (containsUppercase(ch)) countOfUpperCase++;
      else if (containsLowercase(ch)) countOfLowerCase++;
    }
    if (countOfLowerCase < 1) {
      checkPassComplexity.addIssue({
        path: ["password"],
        message: "La contraseña debe incluir al menos una letra mayúscula",
        code: "custom",
      });
    }
    if (countOfNumbers < 1) {
      checkPassComplexity.addIssue({
        path: ["password"],
        message: "La contraseña debe incluir al menos un número",
        code: "custom",
      });
    }
    if (countOfUpperCase < 1) {
      checkPassComplexity.addIssue({
        path: ["password"],
        message: "La contraseña debe incluir al menos una letra minúscula",
        code: "custom",
      });
    }
  });

function RegisterPage() {
  // STATE
  const [formStatus, setFormStatus] = useState(null);
  const [user, setUser] = useState(null)
  const [firebaseEmailErrorMessage, setFirebaseEmailErrorMessage] =
    useState(null);
  const { doUpdateDisplayName, doCreateUser, doSendEmailVerification } =
    useContext(FirebaseContext);
  // STORE
  const urls = useSelector((state) => state.urls);

  // FORM
  const form = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: "",
      lastName: "",
      email: "",
      password: "",
      terms: false,
    },
  });

  const onSubmit = async (formData) => {
    doCreateUser(formData.email, formData.password)
      .then(async (userCredential) => {
        // crear usuario en BD
        const user = userCredential.user;
        setUser(user)
        const url = `${urls.API_URL}/advisors/self_create`;
        doUpdateDisplayName(user, `${formData.name} ${formData.lastName}`);
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: user.uid,
              name: formData.name,
              lastname: formData.lastName,
            }),
          });
          const data = await response.json();
          if (data.status === "OK") {
            // EXITO
            doSendEmailVerification(user)
              .then(() => {
                setFormStatus("OK");
              })
              .catch((error) => {
                Sentry.captureException(error);
              });
          } else {
            setFormStatus("ERROR");
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            // Manejar error de correo electrónico en uso
            setFirebaseEmailErrorMessage(
              "El correo electrónico ya está en uso",
            );
            break;
          case "auth/invalid-email":
            // Manejar error de correo electrónico inválido
            setFirebaseEmailErrorMessage("El correo electrónico es inválido");
            break;
          default:
            // Otros errores
            console.error(error.code, error.message);
            break;
        }
        Sentry.captureException(error);
      });
  };

  const onError = (error) => {
    Sentry.captureException(error);
  };
  return (
    <AuthPageFrame
      user={user}
      title={formStatus == "OK" ? "¡Ya tienes tu cuenta!" : "¡Regístrate hoy!"}
    >
      <div className="space-y-2">
        {formStatus == "OK" ? (
          <div className="md:max-w-xl mx-auto my-4 p-4 bg-blue-50 border border-blue-500 rounded-lg text-center space-y-3">
            <div>
              <p>
                <strong>¡Estás a un paso de entrar a Elevest!</strong>
              </p>
              <p className="pt-4">
                Te hemos enviado un correo a {user.email}. Busca nuestro mensaje, haz clic en el enlace y listo, ¡tu cuenta estará confirmada en un instante!
              </p>
            </div>
          </div>
        ) : (
          <div>
            {formStatus === "ERROR" && (
              <div className="p-4 bg-blue-50 border border-blue-500 rounded-lg justify-items-center">
                <p>
                  <strong>Lo sentimos, no pudimos registrarte.</strong>
                </p>
                <Link
                  to={APP_ROUTES.CONTACT_PAGE}
                  className="text-blue-500 hover:underline"
                >
                  Contáctanos
                </Link>
              </div>
            )}
            <p className="text-2xl text-center pt-4">
              Explora Elevest durante 14 días
              <br />
              <strong className="font-bold">
                gratis y sin restricciones.
              </strong>{" "}
            </p>
            <Form onSubmit={form.handleSubmit(onSubmit, onError)} extraClassName="px-5">
              <div className="space-y-8">
                <div className="space-y-5">
                  <Label id="name">
                    <Input name="name" form={form} placeholder="Nombre" />
                    {form.formState.errors.name && (
                      <ErrorMessage
                        message={form.formState.errors.name.message}
                      />
                    )}
                  </Label>

                  <Label id="lastName">
                    <Input name="lastName" form={form} placeholder="Apellido" />
                    {form.formState.errors.lastName && (
                      <ErrorMessage
                        message={form.formState.errors.lastName.message}
                      />
                    )}
                  </Label>

                  <Label>
                    <Input
                      name="email"
                      form={form}
                      placeholder="micorreo@correo.com"
                    />
                    {form.formState.errors.email && (
                      <ErrorMessage
                        message={form.formState.errors.email.message}
                      />
                    )}
                    {firebaseEmailErrorMessage && (
                      <ErrorMessage message={firebaseEmailErrorMessage} />
                    )}
                  </Label>

                  <Label>
                    <div className="space-y-1">
                      <InputPassword
                        name="password"
                        form={form}
                        placeholder="Contraseña"
                        type="password"
                      />
                    </div>
                    {form.formState.errors.password && (
                      <ErrorMessage
                        message={form.formState.errors.password.message}
                      />
                    )}
                  </Label>

                  <div>
                    <div className="flex flex-row gap-2 w-15">
                      <div className="w-10">
                        <Input
                          type="checkbox"
                          name="terms"
                          form={form}
                          className="w-10"
                        />
                      </div>
                      <p>
                      Al verificar tu correo estarás aceptando los <button
                          onClick={() => LoadTermsAndConditions()}
                          className="text-blue-500 underline"
                        >
                          Términos y Condiciones
                        </button>
                      </p>
                    </div>
                    {form.formState.errors.terms && (
                      <ErrorMessage
                        message={form.formState.errors.terms.message}
                      />
                    )}
                  </div>

                  <button
                    type="submit"
                    className="py-4 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
                  >
                    Regístrate
                  </button>
                </div>

                <div className="text-center">
                  <p>
                    ¿Ya tienes cuenta?{" "}
                    <Link
                      to={ROUTES.LOGIN}
                      className="ml-2 text-blue-500 transition hover:text-blue-400 focus:text-slate-900"
                    >
                      Iniciar sesión
                    </Link>
                  </p>
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
    </AuthPageFrame>
  );
}

export default RegisterPage;
