// COMPONENTS
import EquityDebtDoughnut from "./EquityDebtDoughnut";
import { formatToFloat } from "../../ui/utilities/functions";

const EquityDebtSummarizedContent = ({ portfolioData }) => {
  return (
    <div className="grid grid-cols-[130px,1fr] items-center gap-10 font-normal">
      <div className="relative">
        <div className="w-[130px] h-[130px]">
          <EquityDebtDoughnut chartData={portfolioData} />
        </div>
        <span
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 leading-none font-semibold text-base`}
          style={{ color: portfolioData.color }}
        >
          {formatToFloat(portfolioData.total)}
        </span>
      </div>
      <div className="divide-y divide-slate-300">
        {portfolioData.labels.map((composicion, index) => (
          <div
            key={composicion}
            className="grid grid-cols-6 items-center text-slate-600 dark:text-slate-200 py-2"
          >
            <span
              className="w-4 h-4 rounded-sm"
              style={{
                backgroundColor:
                  portfolioData.datasets[0].backgroundColor[index],
              }}
            />
            <span className="col-span-3 text-sm">{composicion}</span>
            <p className="col-span-2 font-semibold text-base">
              {portfolioData.datasets[0].data[index]}%
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EquityDebtSummarizedContent;
