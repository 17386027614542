import { ACCOUNT_STATUS_MAP } from "./mappings";

const FILTER_COLUMNS = [
  "name",
  "lastname",
  "email",
  "company",
  "accountStatus",
];

export const customGlobalFilter = (row, columnId, filterValue) => {
  //if (FILTER_COLUMNS.includes(columnId) && filterValue.length > 0) {
  let originalValue = row.getValue(columnId); // ESTE LO TENGO QUE COMPRAR CON LO QUE ESCRIBIO.
  // REVISAR SI LO QUE SE ESCRIBIO ESTA EN ALGUNO DE LOS MAPAS.
  if (columnId === "accountStatus") {
    originalValue = ACCOUNT_STATUS_MAP[row.getValue(columnId)].label;
  }
  if (typeof originalValue !== "string") return false;

  return originalValue.toLowerCase().includes(filterValue.toLowerCase());
  // console.log(
  //   "ORIGINAL VALUE: ",
  //   columnId,
  //   typeof originalValue,
  //   originalValue,
  //   originalValue.toLowerCase(),
  //   filterValue.toLowerCase(),
  // );

  // return columnId === "expireTrialDate" ? true : false;
};
