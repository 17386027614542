// eslint-disable no-undef
import moment from "moment";
import { format as dateFormat, formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import "moment/locale/es";
import _ from "lodash";
import numeral from "numeral";
import "numeral/locales/es";
import html2canvas from "html2canvas";
import FileSaver from "file-saver";

import Dictionary from "./Dictionary";
import FundProviderDictionary from "./FundProviderDictionary";
import SETTINGS from "../../../settings/settings";

moment.locale("es");
numeral.locale("es");

// CONSTS

// Configuration
const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: "",
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: "mil",
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: "M",
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: "MM",
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: "B",
    limit: 1e15,
  },
];

// DATE
export const formatDataInfoDate = (date) => {
  const newDate = typeof date === "string" ? new Date(date) : date;
  const fullLocale = "es-CL";

  // Usar Intl.DateTimeFormat para obtener la fecha en el formato deseado
  const formatter = new Intl.DateTimeFormat(fullLocale, {
    day: "numeric",
    month: "long", // Cambiado a 'long' para tener el nombre completo del mes
    year: "numeric",
  });

  // Extraer los componentes (día, mes y año)
  const [{ value: day }, , { value: month }, , { value: year }] =
    formatter.formatToParts(newDate);

  // Retornar la fecha en el formato: "D de MMMM de YYYY"
  return `${day} de ${month} de ${year}`;
};

export const formatDataMonthDate = (date) =>
  moment(date).format(SETTINGS.MOMENT_MONTH_FORMAT);

export function formatMonthFullYear(date) {
  const newDate = typeof date === "string" ? new Date(date) : date;
  const fullLocale = "es-CL";

  const formatter = new Intl.DateTimeFormat(fullLocale, {
    month: "long",
    year: "numeric",
  });

  const [{ value: month }, , { value: year }] =
    formatter.formatToParts(newDate);

  // Convertir la primera letra del mes a mayúscula
  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);

  return `${monthCapitalized} ${year}`;
}

export function formatShortDate(date) {
  return moment(date).format("D [de] MMMM");
}
export const formatReturnsDate = (date) => {
  const newDate = typeof date === "string" ? new Date(date) : date;
  const fullLocale = "es-CL";

  // Obtener componentes de fecha con Intl.DateTimeFormat

  const formatter = new Intl.DateTimeFormat(fullLocale, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  // Extraer los componentes (día, mes y año)
  const [{ value: day }, , { value: month }, , { value: year }] =
    formatter.formatToParts(newDate);

  // Retornar la fecha en el formato personalizado
  return `${day} de ${month}. del ${year}`;
};
export function formatStatsDate(date) {
  return formatDataInfoDate(moment(date, "MM DD, YYYY, h:mm:mmmm a"));
}
export function formatExposuresDate(date) {
  return formatDataInfoDate(moment(date, "YYYY-MM-DD"));
}
export function formatDate(date) {
  return moment(date).format("dddd D [de] MMM [del] YYYY [a las] h:mm a");
}
export function humanizedDate(date) {
  return moment(date).fromNow();
}
export const formatPeriod = (period) =>
  _.capitalize(moment(period, "MMYYYY").format("MMMM [de] YYYY"));
export const formatFullPeriod = (period) =>
  moment(period, "DDMMYYYY").format("MMMM [de] YYYY");
export const humanizePeriod = (period, format = "DDMMYYYY") =>
  moment(period, format).format("dddd D [de] MMMM [del] YYYY");
export const formatTimestampToDate = (timestamp) =>
  moment.unix(timestamp.seconds).format("D [de] MMMM [de] YYYY");
export const formatPythonTimestampToDate = (timestamp) =>
  moment(new Date(timestamp * 1000)).format("D [de] MMMM [de] YYYY");
export const formatDateTimePeriod = (period) =>
  moment(period, "DDMMYYYY H:m:s").format(
    "dddd D [de] MMM [del] YYYY [a las] h:mm a",
  );
export const getDateDayName = (period) =>
  moment(period, "DDMMYYYY H:m:s").format("dddd");
export const formatAnalysisDate = (date) =>
  dateFormat(date, "EEEE d 'de' MMM 'del' yyyy, HH:mm:ss 'hrs' ", {
    locale: es,
  });
export const formatHeader = (date) =>
  dateFormat(date, " d 'de' MMMM", {
    locale: es,
  });
export const formatDateDistance = (date) =>
  formatDistance(date, Date.now(), { addSuffix: true, locale: es });

// STRINGS
export const displayString = (string) => {
  if (typeof string !== "undefined" && string !== null) {
    const s = string
      .toLowerCase()
      .replace("_", " ")
      .split(" ")
      .map((word) => {
        // Capitalize the first letter and keep the rest unchanged
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .map((word) => {
        const parsedWord = Dictionary.get(word);
        return parsedWord || word;
      });

    return s.join(" ");
  }
  return string;
};
export const displayFundProvider = (string) => {
  if (typeof string !== "undefined" && string !== null) {
    const s = string
      .toLowerCase()
      .replace("_", " ")
      .replace(/\w+/g, _.capitalize)
      .split(" ")
      .map((word) => {
        const parsedWord = FundProviderDictionary.get(word);
        return parsedWord || word;
      });
    return s.join(" ");
  }
  return string;
};
export const displayText = (string) => {
  if (typeof string !== "undefined" && string !== null) {
    const s = string
      .toLowerCase()
      .replace("_", " ")
      .split(" ")
      .map((word) => {
        const parsedWord = Dictionary.get(word);
        return parsedWord || word;
      });
    return _.upperFirst(s.join(" "));
  }
  return string;
};
export const toURLString = (string, replaceSpaceWith = "-") =>
  string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, replaceSpaceWith)
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

export const lineBreakAtWord = (string, numWords) => {
  const splitted = string.split(" ");
  const start = splitted.slice(0, numWords).join(" ");
  const rest = splitted.slice(numWords).join(" ");
  return { start, rest };
};

export const doLineBreak = (string, numWords) => {
  const splitted = string.split(" ");
  const start = splitted.slice(0, numWords).join(" ");
  const rest = splitted.slice(numWords).join(" ");
  return (
    <span>
      {start}
      <br />
      {rest}
    </span>
  );
};

// NUMBERS
export const formatToFloat = (number, places = 2, suffix = "%") => {
  if (number === null || number === -1000 || number === undefined) return "-";
  const decimal = "0";
  let formated = numeral(+number).format(`0.${decimal.repeat(places)}`);
  if (+number === 0 || +number % 1 === 0)
    formated = formated.substring(0, formated.length - (places + 1));
  return `${formated}${suffix}`;
};

export const formatToInteger = (number) => `${numeral(+number).format("0,0")}`;

export const formatAmounts = (number) => {
  const format = COUNT_FORMATS.find((fmt) => number < fmt.limit);

  let result = (1000 * number) / format.limit;
  result = Math.round(result * 10) / 10; // keep one decimal number, only if needed

  return `${numeral(+result).format("0.0")} ${format.letter}`;
};

export const formatAmount = (value) => {
  const numberFormat = new Intl.NumberFormat("es-ES");
  const number = numberFormat.format(value);
  return number;
};

export const formatMinimumInvestment = (value) => {
  let formated = value;
  // Si es numérico
  if (!Number.isNaN(+value)) {
    if (value === "1") {
      formated = "No tiene";
    } else {
      formated = formatToInteger(+value, "0a");
    }
  } else if (value !== "S/I") {
    // Si no es numérico y es distinto a "S/I"
    const prefix = value.slice(0, 3);
    const number = value.slice(3);
    formated = `${prefix} ${formatToInteger(number, "0a")}`;
  }
  return formated;
};

// URL
export const prettyURL = (url) => url.replace(/^https?:\/\//i, "");

// PHONE
export const format8DigitPhoneNumber = (number) => {
  const cleaned = `${number}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{4})(\d{4})$/);
  if (match) {
    return `${match[1]} ${match[2]}`;
  }
  return number;
};

// BYTES
export const formatBytes = (number) => numeral(number).format("0.00b");

// OBJECTS
export const isObjEmpty = (object) => Object.keys(object).length === 0;
export const sortObjectByValue = (obj, dir = "desc") =>
  Object.entries(obj)
    .sort(([, a], [, b]) => (dir === "desc" ? b - a : a - b))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

// INPUT VALIDATIONS
export const checkIsNotEmpty = (value) => {
  if (value.trim() === "") {
    return false;
  }
  return true;
};
export const checkIsValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// BROWSER ACTIONS AND PERMISSIONS
export const hasWriteToClipboard = () => {
  let hasPermission = false;
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state === "granted" || result.state === "prompt") {
      hasPermission = true;
    }
  });
  return hasPermission;
};

export const exportComponentAsPNG = (settings) => {
  const { section, filename, quality = 0.9 } = settings;
  // window.scrollTo(0, 0)
  html2canvas(section)
    .then((canvas) => {
      const imageData = canvas.toDataURL("image/png", quality);
      FileSaver.saveAs(imageData, `${filename}.png`);
    })
    .then(() => {
      // console.log("EXPORTADO")
    });
};

// LOCAL STORAGE
export const setInLocalStorage = (key, value) =>
  window.localStorage.setItem(key, value);
export const getFromLocalStorage = (key) => window.localStorage.getItem(key);
export const checkKeyInLocalStorage = (key) =>
  localStorage.getItem(key) !== null;

// COLOURS
export const rgbStringToHex = (color) => {
  const colorValues = color.split("(")[1].split(")")[0];
  const colorArray = colorValues.split(",");
  const hexStringArray = colorArray.map((value) => {
    const x = parseInt(value, 10).toString(16);
    return x.length === 1 ? `0${x}` : x;
  });
  return `#${hexStringArray.join("")}`;
};

export const getContrastColour = (hexcolor) => {
  const r = parseInt(hexcolor?.substring(1, 3), 16);
  const g = parseInt(hexcolor?.substring(3, 5), 16);
  const b = parseInt(hexcolor?.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#0F172A" : "#F8FAFC";
};

// MAPS
export const returnsPeriodMap = new Map([
  ["1 mes", "ret_1m"],
  ["3 meses", "ret_3m"],
  ["6 meses", "ret_6m"],
  ["1 Año", "ret_1y"],
  ["2 Años", "ret_2y"],
  ["3 Años", "ret_3y"],
  ["5 Años", "ret_5y"],
  ["YTD", "ret_ytd"],
]);
export const returnsPeriodMapAPV = new Map([
  ["6 meses", "real_ann_ret_6m"],
  ["1 Año", "real_ann_ret_1y"],
  ["2 Años", "real_ann_ret_2y"],
  ["3 Años", "real_ann_ret_3y"],
  ["5 Años", "real_ann_ret_5y"],
  // ["YTD", "ret_ytd"],
]);

export const formatTimestampToMonthYear = (timestamp) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  return `${month} ${year}`;
};
