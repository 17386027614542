import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EyeIcon } from "@heroicons/react/24/outline";

function AuthInputField({
  type,
  name,
  placeholder,
  defaultValue,
  label,
  callback,
  useShowPassword,
  isRequired,
  readonly,
  size,
}) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [inputType, setInputType] = useState(type);
  const handleChange = (event) => {
    setInputValue(event.target.value);
    callback(event);
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative w-full">
      <div className="mb-2 flex items-center justify-between">
        <label
          htmlFor={name}
          className={`text-slate-500
          ${size === "small" ? "text-xs" : "text-sm"}
        `}
        >
          {label}
        </label>
        {isRequired && (
          <span className="text-xs text-right text-slate-500 ">
            obligatorio
          </span>
        )}
      </div>
      <input
        type={inputType}
        id={name}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        autoComplete="off"
        readOnly={readonly}
        className={`px-4 py-3 w-full text-slate-900 font-medium placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 rounded-lg focus:ring focus:ring-blue-300"
        ${size === "small" ? "p-2 text-sm" : "p-4 text-base"}
        `}
      />
      {useShowPassword && (
        <button
          type="button"
          className={`border-none absolute bottom-0 right-0 bg-transparent text-slate-900  cursor-pointer
          ${size === "small" ? "py-2 px-4" : "p-4"}
          `}
          style={{
            opacity: inputType === "text" ? "1" : "0.25",
          }}
          onClick={(e) => {
            e.preventDefault();
            const newType = inputType === "password" ? "text" : "password";
            setInputType(newType);
          }}
          tabIndex="-1"
        >
          <EyeIcon className="w-5 h-5" />
        </button>
      )}
    </div>
  );
}

AuthInputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  useShowPassword: PropTypes.bool,
  isRequired: PropTypes.bool,
  readonly: PropTypes.bool,
  size: PropTypes.string,
};

AuthInputField.defaultProps = {
  useShowPassword: false,
  isRequired: false,
  readonly: false,
  size: "normal", // normal - small
};

export default AuthInputField;
