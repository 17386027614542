import { cn } from "@/components/shadcn/lib/utils";
import { Badge } from "@/components/shadcn/ui/badge";
import { Button } from "@/components/shadcn/ui/button";
import { ArrowUpDown } from "lucide-react";

import { ACCOUNT_STATUS_MAP } from "./mappings";

export const TableHeader = ({
  column,
  className,
  children,
  useSorting = false,
}) => (
  <div className={cn("text-xs font-bold flex items-center", className)}>
    {children}
    {useSorting && (
      <Button
        variant="ghost"
        size="sm"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        <ArrowUpDown className="w-2 h-2" />
      </Button>
    )}
  </div>
);

export const AccountStatusBadge = ({ status }) => (
  <Badge variant={`user_${status.toLowerCase()}`}>
    {ACCOUNT_STATUS_MAP[status].label}
  </Badge>
);
