import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
// STORE
import * as APP_ROUTES from "@/routes/APP_ROUTES";
import { Link } from "react-router-dom";


function ReovokedSession({ user }) {
  return (
    <AuthPageFrame
      user={user}
      title="Sesión cerrada"
      subtitle={''}
      description=""
    >
      <div className="items-center space-y-8">
        <div className="text-center">
          <p className="pt-4">Tu sesión ha sido cerrada debido a que iniciaste sesión en otro dispositivo.</p>
          <p className="pt-4">¿No fuiste tú?</p>
          <Link className="text-blue-500 text-center block" to={APP_ROUTES.PASSWORD_FORGET}>Cambia tu contraseña</Link>
        </div>
      </div>
    </AuthPageFrame>
  );
}

export default ReovokedSession;
