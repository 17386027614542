import { useContext, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

// ENVIROMENT
import { useSelector, useDispatch } from "react-redux";
import SETTINGS from "../settings/settings";

// STORE
import { updateUser } from "../store/userSlice";
import { updateAppData } from "../store/appDataSlice";
import { updateAnalysisDataConfig } from "../store/analysisDataConfigSlice";
import { updateColorScheme } from "../store/colorSchemeSlice";

// CONTEXTS
import FirebaseContext from "../firebase/FirebaseContext";

// HOOKS
import { useSnapshot } from "../firebase/useSnapshot";
import useColorScheme from "../hooks/useColorScheme";
import useAnalysisConfigHelpers from "../hooks/useAnalysisConfigHelpers";
import { Fetch } from "../hooks/useFetch";

// COMPONENTS
import AppRoutes from "../routes/AppRoutes";
import SplashScreen from "../components/ui/utilities/SplashScreen";
import MaintenancePage from "../pages/MaintenancePage/MaintenancePage";

// SENTRY
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://ffa0b7a6b45cfdc5b485643f168e3fcb@o4508262732660736.ingest.us.sentry.io/4508262734364672",
  release: "1.2",
  environment: SETTINGS.environment,
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  allowUrls: [
    /^https:\/\/lvaindices\.com/,
    /^https:\/\/qa\.elevest\.app/,
    /^https:\/\/asesores\.lvaindices\.com/,
    /^https:\/\/elevest\.app/,
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/lvaindices\.com/,
    /^https:\/\/qa\.elevest\.app/,
    /^https:\/\/asesores\.lvaindices\.com/,
    /^https:\/\/elevest\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // !This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // !If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  // CONTEXT
  const { auth, appConfig, setAnalyticsUserId } = useContext(FirebaseContext);
  // STATE
  const [appDataReceived, setAppDataReceived] = useState(false);
  // const [loggedInUserID, setLoggedInUserID] = useState(null)
  // STORE
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData.data);
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig);
  // const userInStore = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls);
  // FIREBASE
  const firebase = useContext(FirebaseContext);
  // HOOKS
  const { getActiveCoverPagePath } = useAnalysisConfigHelpers();
  const [colorScheme] = useColorScheme();
  const [configData] = useSnapshot(appConfig);

  useEffect(() => {
    // SAVE APP DATA EN STORE
    if (configData) {
      const env = SETTINGS.environment;
      dispatch(
        updateAppData({
          maintenance: configData.environments[env].maintenance,
          screenshot_ability: configData.environments[env].screenshot_ability,
          isBeta: configData.environments[env].isBeta,
        }),
      );
      setAppDataReceived(true);
    }
  }, [configData, dispatch]);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // USER IS LOGGED IN
        // setLoggedInUserID(user.uid)
        try {
          const advisorUrl = `${urls.API_URL}/advisors/${user.uid}/get`;
          Fetch(advisorUrl, {}, { firebase }, (response) => {
            const dbUser = response;
            const loggedUser = {
              uid: user.uid,
              email: user.email,
              createdAt: user.metadata.createdAt,
              creationTime: user.metadata.creationTime,
              lastLoginAt: user.metadata.lastLoginAt,
              lastSignInTime: user.metadata.lastSignInTime,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
              showWelcomeScreen: user.showWelcomeScreen, // TODO.: user.showWelcomeScreen,
              showProfileHint: user.showProfileHint, // TODO.: user.showProfileHint,
              ...dbUser,
            };
            // SAVE ADVISOR DATA TO STORE FOR PUBLIC ANALYSIS
            dispatch(
              updateAnalysisDataConfig({
                ...analysisDataConfig.data,
                advisorData: {
                  contactServices: loggedUser.contactServices,
                  email: loggedUser.email,
                  phone: loggedUser.phone,
                },
                logo: loggedUser.logo,
                colors: loggedUser?.colors ?? null,
                title: "",
                clientName: "",
                clientInvestmentType: "",
                clientProfile: "",
                objective: loggedUser.defaultAnalysisInfo.analysis_objective,
                comments: loggedUser.defaultAnalysisInfo.analysis_comment,
                description:
                  loggedUser.defaultAnalysisInfo.company_info.description,
                url: loggedUser.defaultAnalysisInfo.company_info.url,
                address: loggedUser.defaultAnalysisInfo.company_info.address,
                phone: loggedUser.defaultAnalysisInfo.company_info.phone,
                disclaimer: loggedUser.defaultAnalysisInfo.analysis_disclamer,
                coverPage: getActiveCoverPagePath(loggedUser?.coverPages),
                orientation: loggedUser?.orientation ?? null,
              }),
            );
            // TAG ANALYTICS DATA WITH USER ID
            setAnalyticsUserId(user?.uid);
            // SENTRY TESTS
            Sentry.setUser({
              id: loggedUser.id,
              username: `${loggedUser.name} ${loggedUser.lastname}`,
              email: loggedUser.email,
            });
            // SET INITIAL COLOR SCHEME
            const initialColorScheme = loggedUser?.colorScheme
              ? loggedUser.colorScheme
              : colorScheme;
            dispatch(updateColorScheme(initialColorScheme));
            // SAVE USER TO REDUX STORE
            dispatch(updateUser(loggedUser));
          });
        } catch (err) {
          console.log("Error al recuperar info del Usuario", err);
          Sentry.captureException(err);
        }
      } else {
        // USER IS LOGGED OUT - CLEAR USER DATA FROM STORE
        // setLoggedInUserID(null)
        dispatch(updateUser(null));
        // SENTRY
        if (SETTINGS.environment !== "dev") {
          Sentry.setUser(null);
        }
      }
    });
    return () => listener();
  }, [auth, dispatch]);

  useEffect(() => {
    const doc = document.documentElement;
    if (colorScheme === "light") {
      doc.classList.remove("dark");
      doc.classList.add("light");
    } else {
      doc.classList.remove("light");
      doc.classList.add("dark");
    }
  }, [colorScheme]);

  const error = console.error;
  console.error = (...args) => {
    if (!SETTINGS?.SHOW_PROP_TYPES_ERRORS) {
      if (/defaultProps/.test(args[0])) return;
    }
    error(...args);
  };

  const getAppContent = () =>
    appData.maintenance ? (
      <MaintenancePage />
    ) : (
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    );

  return !appDataReceived ? <SplashScreen /> : getAppContent();
}

export default App;
