import VerifyEmail from './VerifyEmail';
import PasswordReset from './PasswordReset';

const FirebaseAuthActions = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const actionMode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    if (actionMode === "verifyEmail") {
        return <VerifyEmail oobCode={oobCode} />
    }

    if (actionMode === "resetPassword") {
        return <PasswordReset oobCode={oobCode} />
    }
};

export default FirebaseAuthActions;