import { differenceInDays, format } from "date-fns";
import { es } from "date-fns/locale";
import { cn } from "@/components/shadcn/lib/utils";
import {
  ChartNoAxesCombined,
  FileChartLine,
  MoreVertical,
  UserCog,
} from "lucide-react";
import { Button } from "@/components/shadcn/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/shadcn/ui/dropdown-menu";

// TABLE
import { createColumnHelper } from "@tanstack/react-table";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
// COMPONENTS
import { AccountStatusBadge, TableHeader } from "./userTableLayout";
import { Link } from "react-router-dom";

// HELPERS
const columnHelper = createColumnHelper();

export const columns = ({ actionsHandler }) => [
  columnHelper.display({
    accessorKey: "disabled",
    header: () => <TableHeader className="text-center">Activo</TableHeader>,
    cell: ({ row }) => {
      const disabled = row.getValue("disabled");
      return (
        <span
          title={disabled ? "Cuenta deshabilitada" : "Cuenta habilitada"}
          className={cn("block mx-auto w-3 h-3 rounded-full bg-emerald-400", {
            "bg-yellow-200": disabled,
          })}
        />
      );
    },
  }),
  columnHelper.display({
    accessorKey: "name",
    header: ({ column }) => (
      <TableHeader column={column} useSorting>
        Nombre
      </TableHeader>
    ),
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    accessorKey: "lastname",
    header: ({ column }) => (
      <TableHeader column={column} useSorting>
        Apellido
      </TableHeader>
    ),
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    accessorKey: "email",
    header: ({ column }) => (
      <TableHeader column={column} useSorting>
        Correo electrónico
      </TableHeader>
    ),
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    accessorKey: "company",
    header: ({ column }) => (
      <TableHeader column={column} useSorting>
        Empresa
      </TableHeader>
    ),
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    accessorKey: "advisorProposalCount",
    header: ({ column }) => (
      <TableHeader column={column} className="text-center" useSorting>
        Propuestas
      </TableHeader>
    ),
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("advisorProposalCount")}</div>
    ),
    sortingFn: "basic",
  }),
  columnHelper.display({
    accessorKey: "accountStatus",
    header: ({ column }) => (
      <TableHeader column={column} className="text-center" useSorting>
        Estado
      </TableHeader>
    ),
    cell: ({ row }) => {
      const status = row.getValue("accountStatus");
      return (
        <div className="text-center">
          <AccountStatusBadge status={status} />
        </div>
      );
    },
    sortType: (rowA, rowB, columnId) => {
      console.log(rowA, rowB);
      const dateA = new Date(rowA.values[columnId]);
      const dateB = new Date(rowB.values[columnId]);
      return dateA > dateB ? 1 : -1;
    },
  }),
  columnHelper.display({
    accessorKey: "creationTimestamp",
    header: ({ column }) => (
      <TableHeader className="text-center" column={column} useSorting>
        Creación
      </TableHeader>
    ),
    cell: (info) => {
      if (info.getValue("creationTimestamp")) {
        const creationObj = info.getValue("creationTimestamp");
        const creation = format(creationObj, "d 'de' MMM, yyyy", {
          locale: es,
        }).toLowerCase();
        return <div>{creation}</div>;
      } else {
        ("");
      }
    },
  }),
  columnHelper.display({
    accessorKey: "lastSignInTime",
    header: ({ column }) => (
      <TableHeader className="text-center" column={column} useSorting>
        Ingreso
      </TableHeader>
    ),
    cell: (info) => {
      if (info.getValue("lastSignInTime")) {
        const loginObj = info.getValue("lastSignInTime");
        const login = format(loginObj, "d 'de' MMM, yyyy", {
          locale: es,
        }).toLowerCase();
        return <div>{login}</div>;
      } else {
        ("");
      }
    },
  }),
  columnHelper.display({
    accessorKey: "expireTrialDate",
    header: ({ column }) => (
      <TableHeader className="text-center" column={column} useSorting>
        Expiracion
      </TableHeader>
    ),
    cell: (info) => {
      if (info.getValue("expireTrialDate")) {
        const expiryDateObj = info.getValue("expireTrialDate");
        const isFiveDaysAway = differenceInDays(expiryDateObj, new Date());
        const remainingTrialDays = info.row.original.remainingTrialDays
          ? info.row.original.remainingTrialDays
          : "";
        const expiryDate = format(expiryDateObj, "d 'de' MMM", {
          locale: es,
        }).toLowerCase();
        return (
          <div
            className={cn("flex flex-col gap-0 leading-0", {
              "text-rose-500": isFiveDaysAway <= 5,
            })}
          >
            <span className="font-bold">{`${remainingTrialDays} días`}</span>
            <span className="text-sm text-slate-500">{`(${expiryDate})`}</span>
          </div>
        );
      } else {
        ("");
      }
    },
  }),
  columnHelper.display({
    id: "actions",
    cell: ({ row }) => {
      const rowData = row.original;
      const clientID = rowData.uid;
      const clientName = `${rowData.name} ${rowData.lastname}`;
      const disabled = rowData.disabled;
      return (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Abrir menú</span>
              <MoreVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{clientName}</DropdownMenuLabel>

            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              <DropdownMenuItem
                onClick={() =>
                  actionsHandler({ action: "CHANGE_STATUS", client: rowData })
                }
              >
                <UserCog />
                <span>Modificar estado</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem asChild>
                <Link to={`/mis-analisis/?uid=${clientID}`}>
                  <FileChartLine />
                  <span>Ver propuestas</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to={`/estadisticas/?uid=${clientID}`}>
                  <ChartNoAxesCombined />
                  <span>Ver estadisticas</span>
                </Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            {/* <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem
                onClick={() =>
                  actionsHandler({ action: "TOGGLE_INACTIVE", client: rowData })
                }
              >
                {disabled ? (
                  <>
                    <ShieldPlus />
                    <span>Activar cuenta</span>
                  </>
                ) : (
                  <>
                    <ShieldMinus />
                    <span>Desactivar cuenta</span>
                  </>
                )}
              </DropdownMenuItem>
            </DropdownMenuGroup> */}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  }),
];
