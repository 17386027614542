// UI
import { AlertDialog } from "@/components/shadcn/ui/alert-dialog";
import { ActionsChangeStatus } from "./actionHandlers";

const UserActionDialog = ({
  actionDialogOpen,
  handleCloseDialog,
  actionData,
  updateUserData,
}) => {
  // console.log("🚀 ~ actionData:", actionData);

  return (
    actionData && (
      <AlertDialog
        open={actionDialogOpen}
        onOpenChange={(value) => {
          handleCloseDialog(value);
        }}
      >
        {actionData.action === "CHANGE_STATUS" && (
          <ActionsChangeStatus
            actionData={actionData}
            handleCloseDialog={handleCloseDialog}
            updateUserData={updateUserData}
          />
        )}
      </AlertDialog>
    )
  );
};

export default UserActionDialog;
