import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// DEFAULT LOGOS
import APP_LOGO_PARA_DARK from "../../../assets/images/logos/app_logo.svg";
import APP_LOGO_PARA_LIGHT from "../../../assets/images/logos/app_logo_light.svg";
import { isEmpty } from "lodash";

function AnalysisLogo() {
  const colorScheme = useSelector((state) => state.colorScheme.value);
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig);
  const { logo } = analysisDataConfig.data;
  console.log("🚀 ~ AnalysisLogo ~ logo:", logo);
  const hasLogos = !isEmpty(logo);
  const logoNumber = hasLogos ? Object.keys(logo).length : 0;
  const [logoPath, setLogoPath] = useState(null);

  // TODO
  // SI NO HA NI UNA WEA: USAR LOGOS POR DEFECTO
  // SI HA SUBIDO UNO, USAR ESE LOGO PARA LIGHT Y DARK
  // SI TIENE DOS, USAR EL QUE CORRESPONE
  const getExistingPath = () => {
    const key = Object.keys(logo)[0];
    return logo[key];
  };

  console.log("CUANTOS LOGOS: ", logoNumber);

  useEffect(() => {
    switch (logoNumber) {
      case 1:
        console.log("CASO 1");
        setLogoPath(getExistingPath());
        break;
      case 2:
        console.log("CASO 2");
        if (colorScheme === "light") {
          setLogoPath(logo.light);
        } else {
          setLogoPath(logo.dark);
        }
        break;
      default:
        console.log("DEFAULT");
        if (colorScheme === "light") {
          setLogoPath(APP_LOGO_PARA_LIGHT);
        } else {
          setLogoPath(APP_LOGO_PARA_DARK);
        }
    }
  }, [colorScheme]);

  console.log("LOGO PATH: ", logoPath);

  return (
    <div className="flex items-center justify-end">
      <div className="w-[200px] h-16">
        <img
          src={logoPath}
          alt="Logo"
          className="object-contain w-full h-full"
        />
      </div>
    </div>
  );
}
export default AnalysisLogo;
