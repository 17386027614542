import { useState, useRef, useEffect } from "react";

// ASSETS AND COMPONENTS
import APP_LOGO_LIGHT from "../../../assets/images/logos/app_logo_light.svg";
import { Bars2Icon } from "@heroicons/react/24/outline";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { X } from "lucide-react";
import SETTINGS from "@/settings/settings";

const AuthPageFrameHeader = ({ navigation }) => {
  const sidebarRef = useRef();
  const [openSidebar, setOpenSidebar] = useState(false);

  useOnClickOutside(sidebarRef, () => setOpenSidebar(false));

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpenSidebar(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <section>
      <div className="flex items-center justify-between px-7 py-5 bg-white">
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-[175px] mr-14">
              <a href={`landing/index${SETTINGS?.environment === "dev" ? ".html" : ""}`}>
                <img
                  src={`${APP_LOGO_LIGHT}?v=0.6`}
                  alt="Elevest"
                  className="flex shrink-0 self-center w-auto h-full"
                />
              </a>
            </div>
          </div>
        </div>
        {/* DESKTOP */}
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto hidden lg:block">
              <ul className="flex items-center mr-18">
                {navigation.map((item) => {
                  return (
                    <li
                      key={item.label}
                      className="text-gray-600 hover:text-gray-700 font-medium"
                    >
                      <a href={item.path} className="p-5">
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto hidden lg:block">
              <a href="/login" className="text-blue-600 p-5">
                Ingresa
              </a>
              <div className="inline-block">
                <a
                  href="/register"
                  className="w-full text-white font-semibold border border-blue-500 rounded-xl focus:ring focus:ring-blue-500 bg-blue-500 hover:bg-blue-500 transition ease-in-out duration-200 px-4 py-3"
                  type="button"
                >
                  Regístrate
                </a>
              </div>
            </div>
            <div className="w-auto lg:hidden flex ">
              <a
                href="/login"
                className="px-4 py-4 mr-4 w-full font-semibold border border-blue-500 rounded-xl focus:ring focus:ring-blue-500 transition ease-in-out duration-200 text-center text-blue-500"
              >
                Ingresa
              </a>
              <button
                type="button"
                className="shrink-0 w-14 h-14 grid place-content-center bg-blue-500 text-slate-50 rounded-full"
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <Bars2Icon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 bottom-0 w-4/6 sm:max-w-xs z-[999] block transition-all duration-300 lg:hidden
          ${openSidebar ? "left-0 lg:-left-2/3" : "-left-2/3"}
        `}
      >
        <div
          className={`fixed inset-0 bg-gray-800 opacity-80
          ${openSidebar ? "block" : "hidden"}
          `}
        ></div>
        <nav className="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto">
          <div className="flex flex-wrap justify-between h-full">
            <div className="w-full">
              <div className="flex items-center justify-between -m-2">
                <div className="w-auto p-2">
                  <a className="inline-block" href="index.html">
                    <img
                      src={`${APP_LOGO_LIGHT}?v=0.6`}
                      alt="Elevest"
                      className="flex shrink-0 self-center w-auto h-full"
                    />
                  </a>
                </div>
                <div className="w-auto p-2">
                  <button type="button" onClick={() => setOpenSidebar(false)}>
                    <X className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center py-16 w-full">
              <ul>
                {navigation.map((item) => {
                  return (
                    <li key={item.label} className="mb-12">
                      <a
                        className="font-medium hover:text-gray-700"
                        href={item.path}
                      >
                        {item.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="flex flex-col justify-end w-full pb-8">
              <div className="flex flex-wrap">
                <div className="w-full">
                  <div className="block">
                    <a
                      href="/contact"
                      className="w-full text-white font-semibold border border-blue-500 rounded-xl focus:ring focus:ring-blue-500 bg-blue-500 hover:bg-blue-500 transition ease-in-out duration-200 px-4 py-3"
                      type="button"
                    >
                      Solicita tu demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default AuthPageFrameHeader;
