import { useState } from "react"
import { createSearchParams, useNavigate, Link } from "react-router-dom"
import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { useSelector } from "react-redux"
import * as ROUTES from "../../routes/APP_ROUTES"

// STORE

// HOOKS
import { useFetch } from "../../hooks/useFetch"

// COMPONENTS
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner"

function CompaniesPage() {
  // STORE
  const urls = useSelector((state) => state.urls)

  // CONTEXT
  const navigate = useNavigate()

  // DATA
  const [companiesList, setCompaniesList] = useState(null)

  // LIFE CYCLE
  useFetch(
    `${urls.API_URL}/companies/admin_list`,
    {},
    (response) => {
      const { data } = response
      setCompaniesList(data)
    },
    false,
  )

  return (
    <main className="px-8 pt-20 mb-20">
      {!companiesList ? (
        <LoadingSpinner />
      ) : (
        <>
          <header className="mb-5">
            <Link
              to={ROUTES.USER}
              className="text-sm text-slate-400 hover:text-blue-500 flex items-center gap-1 mb-2"
            >
              <ChevronLeftIcon className="w-3 h-3" /> Volver
            </Link>
            <div className="mb-5 grid grid-cols-[1fr,_140px] items-center gap-4">
              <div>
                <h1 className="text-base">Administración de compañías</h1>
                <p className="text-sm text-slate-400">
                  Haz clic sobre la compañía para ver más detalles
                </p>
              </div>
              <Link
                to={ROUTES.EDIT_COMPANY}
                className="w-full h-7 grid place-content-center py-2 text-sm rounded-full transition-all duration-300 text-blue-500 border border-blue-500
                hover:bg-blue-500 hover:text-slate-900"
              >
                Crear compañía
              </Link>
            </div>
          </header>

          <div>
            <table className="min-w-full divide-y divide-slate-600">
              <thead>
                <tr className="group text-xxs font-normal uppercase text-slate-400 text-left">
                  <th className="py-2">Nombre</th>
                  <th className="py-2 text-center">Número de usuarios</th>
                  <th className="py-2 text-center">Editar</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-600">
                {Object.keys(companiesList).map((company) => (
                  <tr key={company} className="font-light text-sm text-left">
                    <td className="py-2">{companiesList[company].name}</td>
                    <td className="py-2 w-[400px] text-center">
                      {companiesList[company].users}
                    </td>
                    <td className="py-2 w-[200px] text-center">
                      <button
                        type="button"
                        className="py-1 px-2 text-xxs rounded-md border border-slate-600 hover:bg-blue-500 hover:text-slate-900"
                        onClick={() =>
                          navigate({
                            pathname: ROUTES.EDIT_COMPANY,
                            search: createSearchParams({
                              cid: company,
                            }).toString(),
                          })
                        }
                      >
                        Ver o editar compañía
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </main>
  )
}

export default CompaniesPage
