import { useState } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";

export const Form = ({ children, onSubmit, extraClassName="" }) => (
  <form
    onSubmit={onSubmit}
    className={`px-11 pt-9 pb-11 bg-white bg-opacity-80 md:max-w-xl mx-auto rounded-4xl shadow-12xl space-y-4 ${extraClassName}`}
  >
    {children}
  </form>
);

export const Label = ({ id, children }) => (
  <label htmlFor={id} className="block">
    {children}
  </label>
);

export const Input = ({ name, form, placeholder = "", type = "text" }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      {...form.register(name)}
      className="px-4 py-3 w-full text-slate-900 font-medium placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 rounded-lg focus:ring focus:ring-blue-300"
    />
  );
};

export const InputPassword = ({ name, form, placeholder = "", type = "text" }) => {
  const [inputType, setInputType] = useState(type);
  return (
    <div className="relative w-full">
      <input
        type={inputType}
        placeholder={placeholder}
        {...form.register(name)}
        autoComplete="off"
        className={`px-4 py-3 w-full text-slate-900 font-medium placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 rounded-lg focus:ring focus:ring-blue-300"`}
      />
      <button
        type="button"
        className={`border-none absolute bottom-0 right-0 bg-transparent text-slate-900  cursor-pointer p-4`}
        style={{
          opacity: inputType === "text" ? "1" : "0.25",
        }}
        onClick={(e) => {
          e.preventDefault();
          const newType = inputType === "password" ? "text" : "password";
          setInputType(newType);
        }}
        tabIndex="-1"
      >
        <EyeIcon className="w-5 h-5" />
      </button>
    </div>
  );
}

export const Textarea = ({ name, form, placeholder = "" }) => {
  return (
    <textarea
      placeholder={placeholder}
      {...form.register(name)}
      className="p-4 w-full h-48 font-medium text-gray-500 placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 resize-none rounded-lg focus:ring focus:ring-blue-300"
    />
  );
};
export const ErrorMessage = ({ message }) => (
  <p className="text-sm text-rose-700">{message}</p>
);
