import { DataFrame } from "danfojs"
import ShareClass from "./ShareClass"
import { getWithExpiry, setWithExpiry } from "./CacheManager"
// SETTINGS
import SETTINGS from "../settings/settings"
import { DataFetch } from "../hooks/useFetch"
// SENTRY
import * as Sentry from "@sentry/browser";


// formattedDate?
const fetchReturnsData = async (fundId, shareClassName, isAPV, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/share_class/${fundId}/${encodeURIComponent(shareClassName )}/${isAPV}/returns`,
      {},
      { firebase },
      "GET"
    )
    // console.log("RETURNS DATA: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchGeneralInfoData = async (fundId, shareClassName, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/share_class/${fundId}/${encodeURIComponent(shareClassName )}/general_info`,
      {},
      { firebase },
      "GET"
    )
    // console.log("GENERAL INFO: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchDataExposures = async (fundId, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/fund/${fundId}/exposures`,
      {},
      { firebase },
      "GET"
    )
    // console.log("EXPOSICIONES: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchDataIssuers = async (fundId, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/fund/${fundId}/issuers`,
      {},
      { firebase },
      "GET"
    )
    // console.log("ISSUERS: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

async function getShareClassData(fundId, shareClassName, isAPV, firebaseInstance) {
  // get the returns (could be in cache)
  // get the exposures (could be in cache)
  // set it to the cache

  try {
    // Check if the fund id is already cached
    const cachedResponse = await getWithExpiry(fundId)
    if (cachedResponse) {
      // there's a fund id, that means there's a exposure
      // we have to check if the serie is cached
      if (cachedResponse.serie[shareClassName]) {
        // the serie is cached, there's no need to add anything
        return cachedResponse
      }
      // the serie is not cached, we have to add it

      // we have to add the serie to the fund id
      const response = await Promise.all([
        fetchReturnsData(fundId, shareClassName, isAPV, firebaseInstance),
        fetchGeneralInfoData(fundId, shareClassName, firebaseInstance),
      ])

      const shareClassReturns = response[0]
      const shareClassGeneralInfo = response[1]

      const returnsType = isAPV ? "real" : "nominal"

      cachedResponse.serie = {
        ...cachedResponse.serie,
        ...{
          [shareClassName]: {
            returns: {
              [returnsType]: shareClassReturns,
            },
            generalInfo: shareClassGeneralInfo,
          },
        },
      }
      // expiry time set to 30 min
      setWithExpiry(fundId, cachedResponse, 1000 * 60 * 60 * 0.5)
      return cachedResponse
    }
    // there's no fund id, we have to add it
    const response = await Promise.all([
      fetchReturnsData(fundId, shareClassName, isAPV, firebaseInstance),
      fetchGeneralInfoData(fundId, shareClassName, firebaseInstance),
      fetchDataExposures(fundId, firebaseInstance),
      fetchDataIssuers(fundId, firebaseInstance),
    ])
    // console.log( "RESPUESTA DEL RETURN: ", response, response[0] )
    const shareClassReturns = response[0]
    const shareClassGeneralInfo = response[1]
    const fundGeneralInfo = shareClassGeneralInfo.fund
    delete shareClassGeneralInfo.fund
    const shareClassExposures = response[2]
    const shareClassIssuers = response[3]
    const returnsType = isAPV ? "real" : "nominal"
    const shareClassData = {
      serie: {
        [shareClassName]: {
          returns: {
            [returnsType]: shareClassReturns,
          },
          generalInfo: shareClassGeneralInfo,
        },
      },
      // here we have to add the exposure getter function
      exposures: shareClassExposures,
      issuers: shareClassIssuers,
      generalInfo: fundGeneralInfo,
    }
    // expiry time set to 30 min
    setWithExpiry(fundId, shareClassData, 1000 * 60 * 60 * 0.5)
    return shareClassData
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

class ShareClassFactory {
  /**
   * Turns Json data into Danfojs DataFrame
   * @param {JSON} jsonData - Data frame in Json format
   * @returns {DataFrame} DataFrame
   */
  static prepareData(jsonData) {
    let df = new DataFrame(jsonData)
    df = df.sortValues("date")
    return df.copy()
  }

  static getShareClassReturnsDF(shareClassReturns) {
    const df = ShareClassFactory.prepareData(shareClassReturns)
    return df
  }

  static async getShareClass(fundId, shareClassName, isAPV, firebaseInstance) {
    const shareClassData = await getShareClassData(
      fundId,
      shareClassName,
      isAPV,
      firebaseInstance
    )
    const returnsType = isAPV ? "real" : "nominal"
    const shareClassReturns =
      shareClassData.serie[shareClassName].returns[returnsType]
    const shareClassGeneralInfo =
      shareClassData.serie[shareClassName].generalInfo
    const fundGeneralInfo = shareClassData.generalInfo
    const returnsDF =
      ShareClassFactory.getShareClassReturnsDF(shareClassReturns)
    const { exposures } = shareClassData
    const { issuers } = shareClassData
    const returnsFirstDay = shareClassReturns[0].date
    const returnsLastDay = shareClassReturns[shareClassReturns.length - 1].date

    return new ShareClass(
      fundId,
      shareClassName,
      returnsFirstDay,
      returnsLastDay,
      returnsDF,
      exposures,
      issuers,
      shareClassGeneralInfo,
      fundGeneralInfo,
    )
  }
}
export default ShareClassFactory
