import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// HOOKS
import { useFetch } from "../../hooks/useFetch";
import usePrepareProposalStatisticsData from "../../components/ui/StatsSections/usePrepareProposalStatisticsData";

// COMPONENTS
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner";
import StatsSidebar from "../../components/ui/StatsSections/StatsProposalAdvisors/StatsSidebar";
import SidebarStatus from "../../components/ui/StatsSections/StatsProposalAdvisors/SidebarStatus";

// UTILS

import { isEqual, sortBy } from "lodash";
import StatsSummaryBoxes from "../../components/ui/StatsSections/StatsProposalAdvisors/StatsSummaryBoxes";
import StatsHistogram from "../../components/ui/StatsSections/StatsProposalCharts/StatsHistogram";
import { useSearchParams } from "react-router-dom";

const DEFAULT_FILTERS = {
  excludeLVA: true,
  advisorID: "ALL",
  companyID: "ALL",
};

function StatisticsPage() {
  // HOOKS
  const [params] = useSearchParams();
  const UIDRef = params.get("uid");
  // STORE
  const urls = useSelector((state) => state.urls);
  // HOOKS
  const {
    totalClients,
    totalCompanies,
    getProposalsGeneralStatistics,
    getAdvisorList,
    getAdvisorName,
    getCompanyName,
  } = usePrepareProposalStatisticsData();

  const getDefaultFilters = () => {
    let defaultFilters = DEFAULT_FILTERS;
    if (UIDRef) {
      defaultFilters = {
        ...DEFAULT_FILTERS,
        ...{ advisorID: UIDRef },
      };
    }
    return defaultFilters;
  };

  // STATE
  const [filters, setFilters] = useState(getDefaultFilters());
  const [rawData, setRawData] = useState(null);
  // DISPLAY
  const [showSidebarBy, setShowSidebarBy] = useState("advisors"); // advisors || companies
  // DATA
  const [proposalData, setProposalData] = useState(null);
  const [proposalGeneralData, setProposalGeneralData] = useState(null);
  const [advisorsList, setAdvisorsList] = useState(null);
  const [companiesList, setCompaniesList] = useState(null);

  const normalizeDate = (dateObj) => {
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // DATA FETCH
  useFetch(
    `${urls.API_URL}/proposals/list_for_statistics`,
    {},
    (response) => {
      const { data } = response;
      //console.log("DATA: ", data);
      const transformedData = data.map((d) => ({
        ...d,
        ...{ timestamp: new Date(d.timestamp) },
        ...{ date: normalizeDate(new Date(d.timestamp)) },
      }));
      // SIDEBAR DATA

      const sidebarData = getAdvisorList(transformedData, filters.excludeLVA);

      setAdvisorsList(
        sortBy(sidebarData.advisors, (o) => o.total_proposal).reverse(),
      );
      setCompaniesList(
        sortBy(sidebarData.companies, (o) => o.total_proposal).reverse(),
      );

      setRawData(transformedData);
    },
    [],
  );

  // FUNCTIONS
  const updateFilters = (key, value) => {
    setFilters({
      ...filters,
      ...{ [key]: value },
    });
  };
  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };
  const areFiltersApplied = () => !isEqual(filters, DEFAULT_FILTERS);

  const switchMode = (mode) => {
    setShowSidebarBy(mode);
    clearFilters();
  };

  // LIFE CYCLE
  useEffect(() => {
    if (rawData) {
      const filteredData = rawData.filter((proposal) => {
        const excludeLVACondition =
          !filters.excludeLVA || proposal.advisor_company.id !== "15";
        const advisorIDCondition =
          filters.advisorID === "ALL" ||
          filters.advisorID === proposal.advisor_id;
        const companyCondition =
          filters.companyID === "ALL" ||
          filters.companyID === proposal.advisor_company.id;

        return excludeLVACondition && advisorIDCondition && companyCondition;
      });
      setProposalGeneralData(getProposalsGeneralStatistics(filteredData));
      setProposalData(filteredData);
    }
  }, [rawData, filters]);

  return (
    <main className="px-8 pt-20 mb-20">
      {!rawData ? (
        <LoadingSpinner />
      ) : proposalData ? (
        <div className="flex items-start justify-between gap-10">
          <aside className="w-[300px] shrink-0 space-y-4">
            <h1 className="text-base font-semibold">Uso Elevest</h1>
            <SidebarStatus
              totalClients={totalClients(proposalData)}
              totalCompanies={totalCompanies(proposalData)}
              clearFilters={clearFilters}
              filtersApplied={areFiltersApplied()}
              showSidebarBy={showSidebarBy}
              switchMode={switchMode}
            />
            <StatsSidebar
              data={proposalData}
              advisors={advisorsList}
              companies={companiesList}
              selectedUserID={filters.advisorID}
              selectedCompanyID={filters.companyID}
              updateFilters={updateFilters}
              showSidebarBy={showSidebarBy}
            />
          </aside>

          <div className="space-y-10 w-full grow-1">
            <div className="space-y-5">
              <h2 className="text-2xl font-bold">
                {areFiltersApplied()
                  ? showSidebarBy === "advisors"
                    ? `Propuestas de ${getAdvisorName(filters.advisorID, proposalData)}`
                    : `Propuestas de ${getCompanyName(filters.companyID, proposalData)}`
                  : "Todas las propuestas"}
              </h2>
            </div>

            <StatsSummaryBoxes
              proposalData={proposalData}
              proposalGeneralData={proposalGeneralData}
            />

            <div className="mb-10">
              <StatsHistogram proposalData={proposalData} />
              {/* <StatsProposalHistogram data={proposalData} /> */}
            </div>
          </div>
        </div>
      ) : (
        <div>Hay un error</div>
      )}
    </main>
  );
}

export default StatisticsPage;
