import SETTINGS from "../../settings/settings";
import { SCREENER } from "@/routes/APP_ROUTES";
// STATE
import { useState } from "react";
// STORE
import { useSelector } from "react-redux";
// HOOKS
import { useFetch } from "@/hooks/useFetch";
import { useNavigate } from "react-router-dom";
// UTILS
import LoadingSpinner from "@/components/ui/loadingSpinner/LoadingSpinner";
import AdminContent from "@/components/ui/admin/AdminContent.jsx";

import { parseAPIDate } from "@/utils/DateCalculations";

function AdminPage() {
  // ROUTER
  const navigate = useNavigate();
  // STORE
  const urls = useSelector((state) => state.urls);
  const user = useSelector((state) => state.user.user);
  // VALIDAR USUARIO
  if (!user.role.includes(SETTINGS.USER_PROFILES.APP_ADMINISTRATOR)) {
    navigate(SCREENER);
  }
  // STATE
  const [rawData, setRawData] = useState(null);

  // FETCH
  useFetch(
    `${urls.API_URL}/advisors/list`,
    {},
    (response) => {
      const { data } = response;
      if (data) {
        // ESTRUCTURAR DATA:
        const newData = Object.keys(data).map((uid) => {
          return {
            ...data[uid],
            uid: uid,
            creationTimestamp: data[uid].creationTimestamp
              ? new Date(data[uid].creationTimestamp)
              : "",
            lastSignInTime: data[uid].lastSignInTime
              ? new Date(data[uid].lastSignInTime)
              : "",
            expireTrialDate: data[uid].expireTrialDate
              ? parseAPIDate(data[uid].expireTrialDate)
              : "",
            advisorProposalCount: data[uid].advisorProposalCount,
            company: data[uid].company.id
              ? data[uid].company.name
              : "Sin empresa",
          };
        });
        setRawData(newData);
      } else {
        // ERROR
      }
    },
    [setRawData],
  );

  return (
    <main className="px-8 pt-20">
      {rawData ? (
        <>
          <div className="">
            <h1 className="text-base">Administración de cuentas</h1>
          </div>
          <AdminContent rawUsersList={rawData} />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </main>
  );
}

export default AdminPage;
