import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

// COMPONENTS
import SETTINGS from "@/settings/settings";
import APP_LOGO_LIGHT from "@/assets/images/logos/app_logo_light.svg"
import LoadingSpinner from '@/components/ui/loadingSpinner/LoadingSpinner';
import RenderIf from '@/components/helpers/RenderIf';

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext";
import * as ROUTES from "../../routes/APP_ROUTES";
import { LoadTermsAndConditions } from '@/components/ui/utilities/LoadTermsAndConditions';


//HOOKS
import { Fetch } from '@/hooks/useFetch';
const VerifyEmail = ({ oobCode }) => {
    // CONTEXT
    const firebase = useContext(FirebaseContext);
    const { doVerifyEmailCode, doSendEmailVerification, doSignOut } = useContext(FirebaseContext);
    const [verificationStatus, setVerificationStatus] = useState("VERIFYING_EMAIL")
    const urls = useSelector((state) => state.urls);

    // HOOKS
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(false);
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        if (!firebase.getCurrentUser()) {
            setVerificationStatus("NOT_LOGGED")
        }
        else if ((firebase.getCurrentUser() && firebase.getCurrentUser().emailVerified)) {
            setVerificationStatus("ALREADY_VERIFIED")
        }
        else if (oobCode) {
            // Verificar el código
            if (oobCode === "not-verified") {
                setVerificationStatus("EMAIL_SENT")
            }
            else {
                doVerifyEmailCode(oobCode)
                    .then(() => {
                        firebase.getCurrentUser().reload()
                        firebase.getIdToken(true)
                        setVerificationStatus("VERIFIED")
                        // enviar correo
                        const advisorUrl = `${urls.API_URL}/advisors/${firebase.getCurrentUser().uid}/send_welcome_email`;
                        Fetch(advisorUrl, {}, { firebase }, (response) => {
                            console.log("Correo de Bienvenida Enviado!!!", response)
                        });
                    })
                    .catch((error) => {
                        console.error('Error verificando el correo:', error);
                        setVerificationStatus("VERIFICATION_ERROR")
                        Sentry.captureException(error);
                    });
            }
        } else {
            setVerificationStatus("VERIFICATION_ERROR")
        }
    }, []);

    useEffect(() => {
        let interval = null;
        if (disabled) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (!disabled && seconds !== 10) {
            setSeconds(10);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [disabled, seconds]);

    useEffect(() => {
        if (seconds === 0) {
            setDisabled(false);
            setSeconds(10);
        }
    }, [seconds]);


    const handleResend = () => {
        console.log("Enviando correo")
        setDisabled(true);
        doSendEmailVerification(firebase.getCurrentUser()).then(() => {
            setVerificationStatus("EMAIL_SENT")
        }).catch((error) => {
            Sentry.captureException(error);
        });
    }

    const handleCloseSession = () => {
        console.log("Cerrando sesion")
        doSignOut().then(() => {
            navigate("/login", { replace: true });
        });
    }

    const resultComponent = () => {
        if (verificationStatus === "VERIFIED") {
            return (
                <>
                    <p className='pb-4'>¡Correo verificado con éxito!</p>
                    <a href={ROUTES.SCREENER} className="text-blue-500 hover:underline">
                        Ingresar a Elevest
                    </a>
                </>
            )
        }
        else if (verificationStatus === "ALREADY_VERIFIED") {
            return (
                <>
                    <p className='pb-4'>El correo ya se encuentra verificado.</p>
                    <a href={ROUTES.SCREENER} className="text-blue-500 hover:underline">
                        Ingresar a Elevest
                    </a>
                </>
            )
        }
        else if (verificationStatus === "NOT_LOGGED") {
            return (
                <>
                    <p className='pb-4'>Debes iniciar sesión para poder verificar tu correo.</p>
                    <a href={ROUTES.LOGIN} className="text-blue-500 hover:underline">
                        Ingresar a tu cuenta
                    </a>
                </>
            )
        }
        else if (verificationStatus === "VERIFICATION_ERROR") {
            return (
                <>
                    <p className='text-balance pb-4'>Tu correo aún no ha sido verificado. Pulse aquí para enviar link de activación.</p>
                    <button type="button" onClick={() => handleResend()} className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-700 text-white">
                        Enviar Correo
                    </button>
                </>
            )
        }
        else if (verificationStatus === "EMAIL_SENT") {
            return (
                <div className="md:max-w-xl mx-auto my-4 p-4 bg-blue-50 border border-blue-500 rounded-lg text-center space-y-3">
                    <div>
                        <p>
                            <strong>¡Estás a un paso de entrar a Elevest!</strong>
                        </p>
                        <p className="pt-4">
                            Revisa tu bandeja de entrada en {firebase.getCurrentUser().email}. Busca nuestro mensaje, haz clic en el enlace y listo, ¡tu cuenta estará confirmada en un instante!
                        </p>

                        <p className="pt-4">Al verificar tu correo estás aceptando los <button onClick={() => LoadTermsAndConditions()}
                            className="text-blue-500 underline"
                        >
                            Términos y Condiciones
                        </button></p>


                    </div>
                    <div className="w-full flex flex-col items-center justify-center text-center gap-3">
                        <p className="pt-4">¿No recibiste el correo?</p>
                        <button
                            type="button"
                            onClick={() => handleResend()}
                            className={`px-4 py-2 rounded-lg ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
                            disabled={disabled}
                        >

                            Reenviar Correo
                        </button>
                        {disabled && (
                            <span className="text-sm text-gray-600 mt-2">
                                Intentar nuevamente en {seconds}s
                            </span>
                        )}
                    </div>
                </div>
            )
        }
        else if (verificationStatus === "VERIFYING_EMAIL") {
            return (
                <>
                    <LoadingSpinner />
                    <p>Estamos verificando tu correo...</p>
                </>
            )
        }

    }

    return (
        <div className="relative w-screen min-h-screen text-slate-900 bg-white flex flex-col justify-start gap-10 items-stretch">
            <section>
                <div className="flex items-center justify-between px-7 py-5 bg-white">
                    <div className="w-auto">
                        <div className="flex flex-wrap items-center">
                            <div className="w-[175px] mr-14">
                                <a href={`landing/index${SETTINGS?.environment === "dev" ? ".html" : ""}`}>
                                    <img
                                        src={`${APP_LOGO_LIGHT}?v=0.6`}
                                        alt="Elevest"
                                        className="flex shrink-0 self-center w-auto h-full"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <RenderIf isTrue={firebase.getCurrentUser()}>
                        <button
                            onClick={() => handleCloseSession()}
                            className="text-white font-semibold border border-blue-500 rounded-xl focus:ring focus:ring-blue-500 bg-blue-500 hover:bg-blue-500 transition ease-in-out duration-200 px-4 py-3"
                        >
                            Cerrar Sesión
                        </button>
                    </RenderIf>
                </div>
            </section>
            <section className="relative pb-10 bg-blueGray-100">
                <div className="relative z-10 container px-4 py-12 mx-auto">
                    <div>
                        <h2 className="text-5xl text-center font-bold font-heading tracking-px-n leading-tight md:max-w-xl mx-auto">
                            Verificación de correo
                        </h2>
                        <h3 className="text-base text-center leading-normal md:max-w-sm mx-auto my-16">
                            {resultComponent()}
                        </h3>
                    </div>
                </div>
            </section>


        </div>
    );
};

export default VerifyEmail;