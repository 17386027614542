import { useState, useEffect, useContext } from "react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { throttle } from "lodash";
// SENTRY
import * as Sentry from "@sentry/browser";
// STORE
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "@/store/userSlice";
// CONTEXT
import { FirebaseContext } from "@/firebase";
// UI
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/ui/dialog";
import {
  WelcomeScreenCarousel,
  WelcomeScreenNavButton,
} from "./WelcomeScreenLayout";
import { Fetch } from "@/hooks/useFetch";
import { cn } from "@/components/shadcn/lib/utils";

const WelcomeScreen = () => {
  const slides = [
    {
      id: "1",
      name: "Descubre",
      src: () => import("@/assets/videos/01-welcome-descubre.mp4"),
      content: [
        "Descubre fondos en forma rápida y precisa utilizando filtros y búsqueda avanzada.",
      ],
    },
    {
      id: "2",
      name: "Analiza",
      src: () => import("@/assets/videos/02-welcome-analiza.mp4"),
      content: [
        "Analiza tus portafolios y ajusta tu selección visualizando el resultado de forma inmediata.",
      ],
    },
    {
      id: "3",
      name: "Comunica",
      src: () => import("@/assets/videos/03-welcome-comunica.mp4"),
      content: [
        "Comunica tus ideas y visión de mercado.",
      ],
    },
    {
      id: "4",
      name: "Tu sello en cada propuesta",
      src: () => import("@/assets/videos/04-welcome-personaliza.mp4"),
      content: [
        "Genera propuestas que reflejen la identidad única de tu marca.",
      ],
    },
  ];

  // CONTEXT
  const firebase = useContext(FirebaseContext);
  // STORE
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const urls = useSelector((state) => state.urls);
  // STATE
  const [dialogOpen, setDialogOpen] = useState(user.showWelcomeScreen);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoPaths, setVideoPaths] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [api, setApi] = useState();

  useEffect(() => {
    const loadVideos = async () => {
      const paths = await Promise.all(slides.map((slide) => slide.src()));
      setVideoPaths(paths.map((path) => path.default));
    };
    loadVideos();
  }, []);

  const handleLoadedData = (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: false }));
  };

  const handleNavigate = (dir) => {
    const following = currentSlide + dir;
    if (following < 0) {
      // SI ESTA EN LA PRIMERA
      setCurrentSlide(currentSlide.length); // IR A LA ÚLTIMA
      api?.scrollTo(slides.length);
    } else if (following > slides.length) {
      // ESTA EN LA ÚLTIMA
      setCurrentSlide(0); // IR A LA PRIMERA.
      api?.scrollTo(0);
    } else {
      api?.scrollTo(following);
      setCurrentSlide(following);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setCurrentSlide(0);
  };

  const getPreviousStepLabel = () => (currentSlide === 0 ? 1 : currentSlide);
  const getNextStepLabel = () =>
    currentSlide === slides.length ? slides.length : currentSlide + 2;

  const handleDontShowAgain = () => {
    // UPDATE USER OBJECT;
    dispatch(updateUser({ ...user, ...{ showWelcomeScreen: false } }));
    closeDialog();
    // POST TO ENDPOINT:
    try {
      Fetch(
        `${urls.API_URL}/advisors/${user.uid}/turn_off_welcome_screen`,
        {},
        { firebase },
        (data) => {
          if (data.status === "OK") {
            // console.log("OK")
          } else {
            throw new Error("Error al intentar cerrar ventana de bienvenida.");
          }
        },
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const showNav = () => currentSlide > 0;
  const isLast = () => currentSlide === slides.length;

  useEffect(() => {
    if (!api) {
      return;
    }

    const handleScroll = (info) => {
      const newCurrentSlide = info.selectedScrollSnap();
      setCurrentSlide(newCurrentSlide);
    };

    api.on("scroll", throttle(handleScroll, 400));
  }, [api]);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent className="border-none w-3/4 max-w-screen-2xl focus-visible:outline-none space-y-5 p-0 overflow-hidden [&>button]:hidden">
        <DialogHeader className="sr-only m-0">
          <DialogTitle>Te damos la bienvenida a Elevest</DialogTitle>
          <DialogDescription>
            Estamos aquí para ayudarte a crear tus mejores propuestas de
            inversión. Descubre lo fácil que es siguiendo solo 4 pasos.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full !m-0 relative">
          <WelcomeScreenCarousel
            setApi={setApi}
            slides={slides}
            loadingStates={loadingStates}
            videoPaths={videoPaths}
            handleLoadedData={handleLoadedData}
            currentSlide={currentSlide}
            user={user}
            handleNavigate={handleNavigate}
            handleDontShowAgain={handleDontShowAgain}
            closeDialog={closeDialog}
          />

          <WelcomeScreenNavButton
            label={`Paso ${getPreviousStepLabel()}`}
            onClick={() => handleNavigate(-1)}
            className={cn("left-5", {
              "opacity-1 pointer-events-auto": showNav(),
            })}
          >
            <ArrowLeft className="w-8 h-8" />
          </WelcomeScreenNavButton>

          {currentSlide !== 0 && (
            <WelcomeScreenNavButton
              label={`Paso ${getNextStepLabel()}`}
              onClick={() => handleNavigate(1)}
              className={cn("right-5", {
                "opacity-1 pointer-events-auto": showNav() && !isLast(),
              })}
            >
              <ArrowRight className="w-8 h-8" />
            </WelcomeScreenNavButton>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeScreen;
