import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";

// PROPTYPES
import { userPropTypes } from "../../propTypes/AppPropTypes";

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext";

// COMPONENTS
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
import FormGroup from "../../components/ui/forms/FormGroup";
import AuthInputField from "../../components/ui/forms/AuthForms/AuthInputField";
import StatusMessage from "../../components/ui/utilities/StatusMessage";

// CONSTANTS
import * as ROUTES from "../../routes/APP_ROUTES";
import { Form } from "@/components/ui/auth/formLayouts/FormLayouts";

const INITIAL_STATE = {
  newPassword: "",
  repeatPassword: "",
  error: null,
  message: "",
  status: "",
};

function PasswordReset({ oobCode }) {
  // CONTEXT
  const { doVerifyPasswordResetCode, doConfirmPasswordReset } =
    useContext(FirebaseContext);

  // STATE
  const [userMail, setUserMail] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [formFields, setFormFields] = useState(INITIAL_STATE);
  const [hasResponse, setHasResponse] = useState(false);
  const [errorInfo, setErrorInfo] = useState({
    status: "",
    message: "",
    link: "/password-forget",
    hasLink: false,
  });
  // VALIDATION
  const passDontMatch = formFields.newPassword !== formFields.repeatPassword;

  // PARAMS FROM FIREBASE
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const { newPassword } = formFields;

    doConfirmPasswordReset(oobCode, newPassword)
      .then(() => {
        setHasResponse(true);
      })
      .catch((error) => {
        let message = "";
        let hasLink = false;
        switch (error.code) {
          case "auth/expired-action-code":
            message =
              "El código para cambio de contraseña expiró. Por favor restaure su contraseña nuevamente";
            hasLink = true;
            break;
          case "auth/invalid-action-code":
            message =
              "El link expiró. Por favor intente restarurar su contraseña nuevamente.";
            hasLink = true;
            break;
          case "auth/user-disabled":
          case "auth/user-not-found":
            message = "El usuario no existe";
            hasLink = true;
            break;
          default:
            message = error.message;
            break;
        }
        setErrorInfo({
          status: "ERROR",
          message,
          hasLink,
        });
      });
  };
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    });
  };

  const isStringLengthAllowed = (str) => {
    if (str.length > 0 && str.length < 6) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    doVerifyPasswordResetCode(oobCode)
      .then((email) => {
        console.log("Email", email)
        setUserMail(email);
        setDataLoaded(true);
      })
      .catch((error) => {
        setDataLoaded(true);
        Sentry.captureException(error);
      });
  }, [userMail, oobCode, doVerifyPasswordResetCode]);

  const getContent = () =>
    !userMail ? (
      <div className="px-11 pt-9 pb-11 bg-white bg-opacity-80 md:max-w-xl mx-auto rounded-4xl shadow-12xl space-y-4 text-center">
        <h1 className="font-semibold mb-2 text-lg">Lo sentimos</h1>
        <p className="mb-1">Este link ya expiró o la contraseña ya cambió.</p>
        <p className="mb-1">
          Puedes intentar recuperar tu clave de ingreso nuevamente o volver a
          ingresar.
        </p>
        <div className="mt-4 flex items-center justify-center gap-4 ">
          <Link
            to={ROUTES.PASSWORD_FORGET}
            className="transition text-blue-500 hover:text-blue-400 focus:text-slate-900"
          >
            {" "}
            Recuperar contraseña
          </Link>
          <Link
            to={ROUTES.LOGIN}
            className=" transition text-blue-500 hover:text-blue-400 focus:text-slate-900"
          >
            Volver a la página de ingreso
          </Link>
        </div>
      </div>
    ) : (
      <Form onSubmit={onSubmitHandler} noValidate className="w-full">
        {!hasResponse ? (
          <>
            {errorInfo.status === "ERROR" && errorInfo.hasLink ? (
              <div>
                <h1 className="font-semibold mb-2 text-lg">Lo sentimos</h1>
                <p className="mb-1">
                  El link no está activo o la contraseña ya cambió.
                </p>
                <p className="mb-1">
                  Si necesitas actualizar tu contraseña por favor, visita la
                  página de{" "}
                  <Link
                    to={ROUTES.PASSWORD_RESET}
                    className="text-blue-400 hover:text-blue-500"
                  >
                    restablecer contraseña
                  </Link>
                  .
                </p>
              </div>
            ) : (
              <>
                <div className="mb-5">
                  <p className="mb-1">Cambiar contraseña para:</p>
                  <p className="text-sm font-semibold">{userMail}</p>
                </div>
                <FormGroup>
                  <AuthInputField
                    type="password"
                    name="newPassword"
                    label="Nueva contraseña"
                    defaultValue={formFields.newPassword}
                    callback={onChangeHandler}
                    placeholder=""
                    useShowPassword
                    isRequired
                  />
                  {!isStringLengthAllowed(formFields.newPassword) && (
                    <p className="text-xs text-rose-400">
                      La contraseña debe tener al menos seis carácteres.
                    </p>
                  )}
                </FormGroup>

                <FormGroup>
                  <AuthInputField
                    type="password"
                    name="repeatPassword"
                    label="Repetir contraseña"
                    defaultValue={formFields.repeatPassword}
                    callback={onChangeHandler}
                    placeholder=""
                    useShowPassword
                    isRequired
                  />
                  {passDontMatch && (
                    <p className="text-xs text-rose-400">
                      Las contraseñas deben coincidir.
                    </p>
                  )}
                </FormGroup>
              </>
            )}

            {formFields.error && (
              <StatusMessage
                title="Encontramos un problema"
                text={formFields.error.message}
                status="error"
              />
            )}

            <div className="flex flex-col items-center justify-center">
              <button
                type="submit"
                className="py-4 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
              >
                Cambiar contraseña
              </button>
              <div className="mt-2 text-center">
                <Link
                  to={ROUTES.LOGIN}
                  className="text-blue-500 transition hover:text-blue-400 focus:text-slate-900"
                >
                  Volver a la página de ingreso
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="px-11 pt-9 pb-11 bg-white bg-opacity-80 md:max-w-xl mx-auto rounded-4xl shadow-12xl space-y-4 text-center">
            <h1 className="font-semibold mb-2 text-lg">
              Puedes volver a ingresar
            </h1>
            <p className="mb-1">Tu contraseña ya está actualizada</p>

            <div className="flex flex-col items-center justify-center">
              <div className="mt-2">
                <Link
                  to={ROUTES.LOGIN}
                  className="text-blue-500 transition hover:text-blue-400 focus:text-slate-900"
                >
                  Volver a la página de ingreso
                </Link>
              </div>
            </div>
          </div>
        )}
      </Form>
    );

  return (
    <AuthPageFrame title="Restablece tu contraseña">
      <div>{!dataLoaded ? "..cargando" : getContent()}</div>
    </AuthPageFrame>
  );
}

PasswordReset.propTypes = {
  user: userPropTypes,
};
PasswordReset.defaultProps = {
  user: null,
};

export default PasswordReset;
