import { useState, useContext } from "react";
import { cn } from "@/components/shadcn/lib/utils";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";

import { DataFetch } from "@/hooks/useFetch";

import { FirebaseContext } from "@/firebase";

import { useSelector } from "react-redux";

import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogFooter,
} from "@/components/shadcn/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/ui/select";
import { Calendar } from "@/components/shadcn/ui/calendar";
import "react-day-picker/dist/style.css";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/shadcn/ui/popover";
import { Button } from "@/components/shadcn/ui/button";
import { Label } from "@/components/shadcn/ui/label";

import { ACCOUNT_STATUS_MAP } from "./mappings";

export const ActionsChangeStatus = ({
  actionData,
  handleCloseDialog,
  updateUserData,
}) => {
  const [selected, setSelected] = useState(actionData.client.accountStatus);
  const [selectedDate, setSelectedDate] = useState(
    actionData.client.expireTrialDate
      ? actionData.client.expireTrialDate
      : null,
  );
  const [status, setStatus] = useState(null);
  const firebase = useContext(FirebaseContext);
  const urls = useSelector((state) => state.urls);

  // HANDLERS
  const handleChangeState = async () => {
    setStatus(null);
    const data = {
      newAccountStatus: selected,
    };

    if (selected === "ON_TRIAL") {
      data["newEndDate"] = selectedDate;
      if (!selectedDate) {
        setStatus({
          status: "ERROR",
          message: "Debes seleccionar una fecha de término del periodo.",
        });
        return;
      }
    }

    try {
      const responseData = await DataFetch(
        `${urls.API_URL}/advisors/${actionData.client.uid}/update_status`,
        JSON.stringify(data),
        { firebase },
        "POST",
        "application/json",
      );
      // console.log("RETURNS DATA: ", responseData);
      if (responseData.status === "ERROR") {
        throw new Error("No logramos cambiar el estado, inténtalo de nuevo");
      } else {
        setStatus({
          status: "SUCCESS",
          message: "El estado cambió.",
        });
        updateUserData(actionData.client.uid, data);
      }
      return responseData;
    } catch (error) {
      //throw new Error(error)
      setStatus({
        status: "ERROR",
        message: error.message,
      });
    }
  };
  const handleCancel = () => {
    setSelected(actionData.client.accountStatus);
    setSelectedDate(null);
    handleCloseDialog(false);
  };

  // CALLBACKS
  const handleSelected = (value) => {
    setStatus(null);
    setSelected(value);
  };
  const handleSelectedDate = (value) => {
    setStatus(null);
    setSelectedDate(value);
  };

  return (
    <AlertDialogContent className="space-y-4">
      <AlertDialogHeader>
        <AlertDialogTitle>{`Cambiar estado a ${actionData.client.name} ${actionData.client.lastname}`}</AlertDialogTitle>
        <AlertDialogDescription>
          Al seleccionar el estado <em>Periodo de Prueba</em> deberás incluír su
          fecha de término.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <div className="space-y-2">
        <div className="flex items-center justify-start gap-4">
          <div className="space-y-2 flex-1 flex-grow">
            <Label>Estado</Label>
            <Select onValueChange={handleSelected} defaultValue={selected}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Selecciona un estado" />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(ACCOUNT_STATUS_MAP).map((optionKey) => (
                  <SelectItem
                    key={optionKey}
                    value={optionKey}
                    disabled={optionKey === actionData.client.accountStatus}
                  >
                    {ACCOUNT_STATUS_MAP[optionKey].label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {selected === "ON_TRIAL" && (
            <div className="space-y-2 flex-1 flex-grow">
              <Label>Fecha de término del periodo</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "pl-3 text-left font-normal w-full",
                      !selectedDate && "text-muted-foreground",
                    )}
                  >
                    {selectedDate ? (
                      format(selectedDate, "PPP", { locale: es })
                    ) : (
                      <span>Selecciona una fecha</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={selectedDate}
                    disabled={{ before: new Date() }}
                    onSelect={handleSelectedDate}
                    initialFocus
                    locale={es}
                    captionLayout="dropdown"
                    classNames={{
                      month: "space-y-4",
                      caption:
                        "flex justify-between pt-1 relative items-center gap-2 px-2",
                      caption_label:
                        "flex items-center gap-1 text-sm font-medium capitalize",
                      caption_dropdowns: "w-full flex justify-between gap-2",
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          )}
        </div>
        {status && (
          <p
            className={cn(
              "text-sm text-rose-700 px-2 py-1 bg-rose-100 rounded-sm",
              { "text-emerald-700 bg-emerald-100": status.status !== "ERROR" },
            )}
          >
            {status.message}
          </p>
        )}
      </div>
      <AlertDialogFooter>
        <Button onClick={handleChangeState}>Cambiar estado</Button>
        <Button onClick={handleCancel} variant="ghost">
          Cerrar
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
