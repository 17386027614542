import { sortBy } from 'lodash';

const getCompositionsData = (rawData, maxRows = 6) => {
  const newChartsData = {};
  const exclusion = ["OTROS", "OTRO"];
  // El label que se usa para elementos fuera del top
  const otherLabel = "Otros";
  const cashLabel = "Caja y Equivalentes";
  let hasCash = false;

  Object.keys(rawData).forEach((compKey) => {
    const elementContent = [];
    
    // Por cada elemento de la composición (compKey), crear un nuevo objeto que incluya una llave (total) con la suma de los valores.
    rawData[compKey].forEach((element) => {
      // Se crea un updatedElement en caso de necesitar modificar parámetros
      const updatedElement = { ...element };
      // Se cambia caja y otros por caja y equivalentes
      if (updatedElement.label.toUpperCase() === "CAJA Y EQUIVALENTES") {
        updatedElement.label = cashLabel;
        hasCash = true;
      }
      const newElementData = {
        ...updatedElement,
        total: element.valueB ? element.value + (element.valueB ?? 0) : element.value,
      };

      // Agregar el nuevo objeto al arreglo elementContent
      elementContent.push(newElementData);

      // RESETEAR hasCash
      hasCash = false;
    });

    // Ordenar el nuevo arreglo según el valor "total" de cada objeto
    let sortedElement = sortBy(elementContent, ["total"]).reverse();

    // Si tiene caja, dejar ese item al final
    if (hasCash) {
      const cashItem = sortedElement.find((item) => item.label === cashLabel);
      const otherItems = sortedElement.filter((item) => item.label !== cashLabel);
      sortedElement = [...otherItems, cashItem];
    }

    // Determinar si la composición es individual o una comparación.
    const hasDoubleBar = "valueB" in sortedElement[0];
    let consolidatedElement = [];
    
    // Si la composición tiene más de "maxRows", se crea "otros"
    if (sortedElement.length > maxRows) {
      // Crear elemento otros para agregar al final del arreglo
      const otros = [{ label: otherLabel, value: 0 }];

      // Crear un nuevo arreglo con todos los elementos que NO sean "Otros" u "Otro"
      const exOtros = sortedElement.filter(
        (obj) => ![...exclusion, cashLabel.toUpperCase()].includes(obj.label.toUpperCase())
      );

      const cashItem = sortedElement.filter((obj) => cashLabel.includes(obj.label));

      // Almacenar los primeros (maxRows - 1) elementos del arreglo sin "otros"
      let topItems = [];
      if (hasCash) {
        topItems = [...exOtros.slice(0, maxRows - 2), cashItem[0]];
      } else {
        topItems = exOtros.slice(0, maxRows - 1);
      }

      // Sumar todos los values (y valuesB) de cada elemento de topItems
      let topTotalValues = 0;
      let topTotalValuesB = 0;
      let totalValues = 0;
      let totalValuesB = 0;

      sortedElement.forEach((item) => {
        totalValues += item.value;
        if (hasDoubleBar) totalValuesB += item.valueB ?? 0;
      });

      topItems.forEach((item) => {
        topTotalValues += item.value;
        if (hasDoubleBar) topTotalValuesB += item.valueB ?? 0;
      });

      // Asignar al objeto "otros" el valor que falta para completar el 100% de la composición
      otros[0].value = totalValues - topTotalValues;
      if (hasDoubleBar) otros[0].valueB = totalValuesB - topTotalValuesB;

      // Consolidar ambos arreglos
      consolidatedElement = [...topItems, ...otros];

      // Agregar composición a la data global
      newChartsData[compKey] = sortByLabel(consolidatedElement, "Sin Información");
    } else {
      newChartsData[compKey] = sortByLabel(sortedElement, "Sin Información");
    }
  });

  return newChartsData;
};

const sortByLabel = (arr, label) =>
  arr.sort((obj1, obj2) => {
    if (obj1.label === label && obj2.label !== label) {
      return 1;
    }
    if (obj1.label !== label && obj2.label === label) {
      return -1;
    }
    return 0;
  });

export { getCompositionsData };