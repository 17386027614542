export const loadingTitle = "Elevest | Preparando propuesta";
export const loadingFavicon =
  "https://firebasestorage.googleapis.com/v0/b/iadvisor-qa.appspot.com/o/favicon.ico?alt=media&token=08bb3a13-4b41-44f8-abf4-cf2de5683ae3";

export const loadingBody = `<div style="width: 100vw;height:100vh;position:relative;background-color:white;">
    <div style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
        <p style="color:#0f172a;font-family:sans-serif;font-size:14px;font-weight:400;text-align="center"> Elevest </p>
        <p style="color:#0f172a;font-family:sans-serif;font-size:14px;font-weight:400;text-align="center">Por favor espera. Estamos preparando tu propuesta...</p>
        </div>
    </div>`;

{/* <img style="display:block; width:120px; height:auto; margin:0 auto;" src="https://firebasestorage.googleapis.com/v0/b/iadvisor-qa.appspot.com/o/lvaasesores.png?alt=media&token=998b5f2c-5e0d-45c9-a54a-d242b0403970" alt="Elevest">     */}