import { useEffect, useRef } from "react";
// UTILS
import { cn } from "@/components/shadcn/lib/utils";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
// UI
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/shadcn/ui/carousel";
import { Button } from "@/components/shadcn/ui/button";
// ASSETS
import BG_INTRO from "../../../assets/images/bg/bg_welcome_screen.png";
import LOGO_ELEVEST from "../../../assets/images/logos/elevest_welcome.svg";

export const WelcomeScreenNavButton = ({ onClick, className, children }) => (
  <Button
    type="button"
    onClick={onClick}
    className={cn(
      "grid place-content-center opacity-0 transition-opacity duration-75 w-12 h-12 rounded-full absolute top-1/2 pointer-events-none",
      className,
    )}
  >
    <span className="inline-flex gap-1 items-center">{children}</span>
  </Button>
);

export const SkipTutorial = ({ handleDontShowAgain }) => (
  <Button
    type="button"
    variant="ghost"
    className="text-sm text-slate-500"
    onClick={() => handleDontShowAgain()}
  >
    Cerrar y no volver a mostrar
  </Button>
);
export const WatchLater = ({ closeDialog }) => (
  <Button
    type="button"
    variant="ghost"
    className="text-sm text-slate-500"
    onClick={() => closeDialog()}
  >
    Ahora no
  </Button>
);

export const WelcomeScreenCarousel = ({
  setApi,
  slides,
  loadingStates,
  videoPaths,
  handleLoadedData,
  currentSlide,
  user,
  handleNavigate,
  handleDontShowAgain,
  closeDialog,
}) => (
  <Carousel setApi={setApi}>
    <CarouselContent>
      <CarouselItem>
        <div className="lg:grid grid-cols-5 h-full">
          <div
            className="col-span-2 h-full bg-no-repeat bg-cover grid place-content-center scale-[1.1] overflow-hidden"
            style={{ backgroundImage: `url(${BG_INTRO})` }}
          >
            <img src={LOGO_ELEVEST} alt="Elevet App" />
          </div>
          <div className="col-span-3 grid place-content-center relative">
            <div className="w-4/5 mx-auto space-y-8 text-center">
              <h3 className="text-3xl font-bold text-blue-500">
                Te damos la bienvenida, {user.name}
              </h3>
              <p>
                Estamos aquí para ayudarte a crear tus mejores propuestas de
                inversión. Descubre lo fácil que es{" "}
                <strong className="font-bold">siguiendo solo 4 pasos</strong>.
              </p>
              <div className="inline-flex flex-col gap-1">
                <Button
                  size="lg"
                  className="bg-blue-600 font-bold uppercase"
                  onClick={() => handleNavigate(1)}
                >
                  Ok, comencemos
                </Button>
              </div>
            </div>
            <div className="inline-flex gap-5 items-center justify-center absolute bottom-4 left-1/2 -translate-x-1/2">
              <SkipTutorial handleDontShowAgain={handleDontShowAgain} />
              <WatchLater closeDialog={closeDialog} />
            </div>
          </div>
        </div>
      </CarouselItem>

      {slides.map((slide, index) => (
        <CarouselItem
          key={slide.id}
          className="relative flex justify-center items-center"
        >
          {loadingStates[slide.id] && (
            <div className="absolute inset-0 flex items center justify-center">
              Cargando...
            </div>
          )}
          {slides[index] && (
            <div className="relative w-full min-h-[540px] flex flex-col justify-end items-center gap-8 py-10">
              <div className="absolute top-2 right-2">
                <SkipTutorial handleDontShowAgain={handleDontShowAgain} />
              </div>
              <div className="flex items-center justify-center gap-4 w-2/3 mx-auto text-blue-500 font-bold">
                <p className="text-6xl">{slide.id}</p>
                <div className="text-xl">
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {slide.content.join(" ")}
                  </Markdown>
                </div>
              </div>
              <div className="w-2/3 shadow-2xl shadow-blue-200">
                <WelcomeVideo
                  slide={slide}
                  videoPath={videoPaths[index]}
                  index={index + 1} // PARA HOMOLOGAR EL INDEX AL CURRENT SLIDE
                  currentSlide={currentSlide}
                  handleLoadedData={handleLoadedData}
                />
              </div>
            </div>
          )}
        </CarouselItem>
      ))}
    </CarouselContent>
  </Carousel>
);

export const WelcomeVideo = ({
  slide,
  videoPath,
  index,
  currentSlide,
  handleLoadedData,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && currentSlide === index) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  });

  return (
    <video
      ref={videoRef}
      id={`welcome-${slide.id}`}
      src={videoPath}
      preload={index === currentSlide ? "auto" : "metadata"}
      onLoadedData={() => {
        handleLoadedData(slide.id);
      }}
      autoPlay
      muted
      loop
      className="w-full h-auto"
    />
  );
};
