import { useEffect, useContext } from "react";
import axios from "axios";
import hash from "object-hash";
import * as APP_ROUTES from "@/routes/APP_ROUTES";
// CONTEXT
import FirebaseContext from "../firebase/FirebaseContext";
// SENTRY
import * as Sentry from "@sentry/browser";

const CACHE = {};

// FETCH HELPERS
const fetchData = async (method, url, data, token) => {
  const config =
    method === "get"
      ? {
          method,
          url,
          params: { ...data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {
          method,
          url,
          data: { ...data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  return axios(config);
};

const useFetch = (
  url,
  params,
  responseFunction,
  useCache = false,
  dependencyList = [],
  method = "get",
) => {
  const firebase = useContext(FirebaseContext);
  const currentUser = firebase.getCurrentUser();

  useEffect(() => {
    let mounted = true;
    const hashedObj = hash({ url, params });
    if (useCache && CACHE[hashedObj] !== undefined) {
      responseFunction(CACHE[hashedObj]);
    } else {
      const performFetch = async (idToken) => {
        try {
          const response = await fetchData(method, url, params, idToken);
          if (response.status === 403 && response.data.message === "TOKEN_REVOKED") {
            window.location.href = APP_ROUTES.REVOKED_SESSIONS
          }
          if (mounted) {
            CACHE[hashedObj] = response;
            responseFunction(response);
          }
        } catch (error) {
          console.error("Fetch error: ", error);
          const errorResponse = error.response;
          if (errorResponse.status === 403 && errorResponse.data.message === "TOKEN_REVOKED") {
            window.location.href = APP_ROUTES.REVOKED_SESSIONS
          }
          Sentry.captureException(error);
          // Aquí se puede manejar el error, se puede crear un hook para mostrar y aqui se puede llamar
          if (mounted) {
            responseFunction({ error: true, message: error.message });
          }
        }
      };

      if (currentUser != null) {
        firebase
          .getIdToken()
          .then(performFetch)
          .catch((error) => {
            Sentry.captureException(error);
            console.error("Token error: ", error);
            if (mounted) {
              responseFunction({ error: true, message: error.message });
            }
          });
      } else {
        performFetch(undefined);
      }
    }

    return () => {
      mounted = false;
    };
  }, dependencyList);
};

// FETCH METHOD
const Fetch = async (
  url,
  data,
  context,
  callback,
  useCache = false,
  method = "post",
) => {
  const { firebase } = context;
  const currentUser = firebase.getCurrentUser();
  const hashedObj = hash({ url, params: data });
  if (CACHE[hashedObj] !== undefined && useCache) {
    callback(CACHE[hashedObj].data);
    return;
  }

  const performFetch = async (idToken) => {
    try {
      const response = await fetchData(method, url, data, idToken);
      if (response.status === 403 && response.data.message === "TOKEN_REVOKED") {
        window.location.href = APP_ROUTES.REVOKED_SESSIONS
      }
      CACHE[hashedObj] = response;
      callback(response.data);
    } catch (error) {
      console.error("Fetch error: ", error);
      const errorResponse = error.response;
      if (errorResponse.status === 403 && errorResponse.data.message === "TOKEN_REVOKED") {
        window.location.href = APP_ROUTES.REVOKED_SESSIONS
      }
      Sentry.captureException(error);
      callback({ error: true, message: error.message });
    }
  };

  if (currentUser != null) {
    firebase
      .getIdToken()
      .then(performFetch)
      .catch((error) => {
        console.error("Token error: ", error);
        Sentry.captureException(error);
        callback({ error: true, message: error.message });
      });
  } else {
    performFetch(undefined);
  }
};

// UPDATED METHODS
const DataFetch = async (url, data, context, method = "post", contentType = null) => {
  const { firebase } = context;
  const currentUser = firebase.getCurrentUser();
  const performFetch = async (idToken, postData) => {
    let options = { headers: { Authorization: `Bearer ${idToken}` } };
    if (contentType) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          "Content-Type": contentType,
        },
      };
    }
    if (method.toLocaleLowerCase() === "post") {
      options = {
        ...options,
        method: method,
        body: postData,
      };
    }
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.status === 403 && data.message === "TOKEN_REVOKED") {
        window.location.href = APP_ROUTES.REVOKED_SESSIONS
      }
      return data;
    } catch (error) {
      Sentry.captureException(error);
      // console.error("Fetch error: ", error)
      return { error: true, message: error.message };
    }
  };

  if (currentUser != null) {
    return firebase
      .getIdToken()
      .then((idToken) => performFetch(idToken, data))
      .catch((error) => {
        // console.error("Token error: ", error)
        Sentry.captureException(error);
        return { error: true, message: error.message };
      });
  } else {
    return performFetch(undefined);
  }
};
const PostFile = async ({ url, formData, context, onComplete }) => {
  const firebase = context;
  const currentUser = firebase.getCurrentUser();

  const sendFile = async (idToken) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (response.status === 403 && data.message === "TOKEN_REVOKED") {
        window.location.href = APP_ROUTES.REVOKED_SESSIONS
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      onComplete(data);
    } catch (error) {
      console.error("Token error: ", error);
      Sentry.captureException(error);
    }
  };

  if (currentUser != null) {
    firebase
      .getIdToken()
      .then(sendFile)
      .catch((error) => {
        Sentry.captureException(error);
        console.error("Token error: ", error);
        // onComplete({ error: true, message: error.message })
      });
  }
};

export { useFetch, Fetch, PostFile, DataFetch };
