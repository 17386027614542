import { getCompositionsData } from "@/utils/chartFunctions"

// PROPTYPES
import { panelDataPropTypes } from "../../../propTypes/AppPropTypes"
// HOOKS
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"
// CHART
import CompositionsChart from "../../charts/CompositionsChart/CompositionsChart"
// import CompositionPie from "../../charts/CompositionPie/CompositionPie"
// UTILITIES
import { EXPOSURE_TYPE_NAME_MAPPING } from "../../../utils/ExposurePresenter"

import { formatDataInfoDate } from "../utilities/functions"
import CompositionsOver100PercAlert from "../CompositionsOver100PercAlert/CompositionsOver100PercAlert"

function FundDetailsExposure({ data }) {
  const formatedData = {}
  Object.keys(data.allocations.values).forEach((key) => {
    formatedData[key] = data.allocations.values[key].data
  })

  const { getCompositionsOrder, compositionHasOver100Perc } = usePrepareDataForCharts()
  const compositionsData = getCompositionsData(formatedData)
  const showWarningOver100Perc = compositionHasOver100Perc(compositionsData)

  return (
    <section className="h-full">
      <header className="mb-6">
        <h1 className="overview-section-title !mb-0">Composiciones</h1>
        <p className="text-xs text-slate-500 dark:text-slate-400">
          Al {formatDataInfoDate(data.allocations.allocations_date)}
        </p>
      </header>
      <div className="grid grid-cols-12 items-start gap-20 3xl:gap-36">
        {getCompositionsOrder().map((item) => {
          const name = `Por ${EXPOSURE_TYPE_NAME_MAPPING[item]}`
          const rows = compositionsData[item]
          // console.log("ROWS: ", rows)
          return (
            rows && (
              <div key={name} className="col-span-4">
                {/* <CompositionPie chartData={rows} name={name} /> */}
                <CompositionsChart
                  chartData={rows}
                  name={name}
                  colors={["#93C5FD"]}
                />
              </div>
            )
          )
        })}
      </div>
      <div className="mt-10">
        <CompositionsOver100PercAlert shouldShow={showWarningOver100Perc} isFund={true} />
      </div>
    </section>
  )
}
FundDetailsExposure.propTypes = {
  data: panelDataPropTypes.isRequired,
}
export default FundDetailsExposure
