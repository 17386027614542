// DATA
import { useState, useEffect } from "react";
import UsersTable from "./usersData/UsersTable.jsx";
import { columns } from "./usersData/usersColumns";
import UserActionDialog from "./usersData/UserActionDialog.jsx";
import { getDifferenceInDays } from "@/utils/DateCalculations.js";

const AdminContent = ({ rawUsersList }) => {
  // STATE
  const [excludeLVA, setExcludeLVA] = useState(true);
  const [data, setData] = useState(null);
  // ACTIONS
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionDialogData, setActionDialogData] = useState(null);

  // LIFE CYCLE
  useEffect(() => {
    let returnList = rawUsersList;
    if (excludeLVA === true) {
      returnList = rawUsersList.filter((user) => user.company !== "LVA");
    }
    setData(returnList);
  }, [excludeLVA]);
  useEffect(() => {
    if (actionDialogData) {
      setActionDialogOpen(true);
    }
  }, [actionDialogData]);

  // CALLBACKS
  const actionsHandler = (actionData) => {
    setActionDialogData(actionData);
  };
  const handleCloseDialog = (value) => {
    if (value === false) {
      setActionDialogData(null);
      setActionDialogOpen(value);
    }
  };

  const updateUserData = (uid, clientData) => {
    const newDataList = [];
    data.forEach((client) => {
      if (uid === client.uid) {
        let newClient = {
          ...client,
          ...{ accountStatus: clientData.newAccountStatus },
        };
        if (clientData.newEndDate) {
          const remainingTrialDays = getDifferenceInDays(
            clientData.newEndDate,
            new Date(),
          );
          newClient = {
            ...newClient,
            ...{
              expireTrialDate: clientData.newEndDate,
              remainingTrialDays: remainingTrialDays + 1,
            },
          };
        }
        newDataList.push(newClient);
      } else {
        newDataList.push(client);
      }
    });
    setData(newDataList);
  };

  return (
    data && (
      <>
        <UsersTable
          data={data}
          columns={columns({ actionsHandler })}
          excludeLVA={excludeLVA}
          setExcludeLVA={setExcludeLVA}
        />
        {actionDialogData && (
          <UserActionDialog
            actionDialogOpen={actionDialogOpen}
            handleCloseDialog={handleCloseDialog}
            actionData={actionDialogData}
            updateUserData={updateUserData}
          />
        )}
      </>
    )
  );
};

export default AdminContent;
