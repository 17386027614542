import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useState } from "react";
// STORE
import { useSelector } from "react-redux";
import APP_LOGO_LIGHT from "@/assets/images/logos/app_logo_light.svg"

function Subscribe() {
    const urls = useSelector((state) => state.urls);
    const userAccount = useSelector((state) => state.user.user);
    const [formStatus, setFormStatus] = useState("")
    // CONTEXT
    const navigate = useNavigate()
    const formatDays = (days) => {
        if (days != 1) {
            return `${days} días`;
        } else {
            return `${days} día`;
        }
    };

    const submitForm = async () => {
        // enviarlo al url de la API

        const response = await fetch(`${urls.API_URL}/send_ticket`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: userAccount.name + " " + userAccount.lastname,
                email: userAccount.email,
                subject: "CONTRATAR",
                message: "Aún no se acaba mi periodo de prueba pero quiero contratar",
            }),
        });

        if (!response.status !== "200") {
            Sentry.captureException(
                new Error("Error en envío de correo de contacto."),
            );
        }
        await response.json();
        setFormStatus("Accepted")
    };

    const declineForm = () => {
        navigate("/", { replace: true });
    }

    return (
        <div className="relative w-screen min-h-screen text-slate-900 bg-white flex flex-col justify-start gap-10 items-stretch">
            {/* Header */}
            <section>
                <div className="flex items-center justify-between px-7 py-5 bg-white">
                    <div className="w-auto">
                        <div className="flex flex-wrap items-center">
                            <div className="w-[175px] mr-14">
                                <a href={`/screener`}>
                                    <img
                                        src={`${APP_LOGO_LIGHT}?v=0.6`}
                                        alt="Elevest"
                                        className="flex shrink-0 self-center w-auto h-full"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* BODY */}
            <section className="relative pt-10 pb-10 bg-blueGray-100">
                {formStatus === "Accepted" ? (
                    <div className="relative z-10 container px-4 mx-auto">
                        <div>
                            <p className="pb-6 mb-6 text-xm text-blue-500 text-center font-semibold uppercase tracking-px">
                                {"Tu periodo de prueba aún no termina"}
                            </p>
                            <h2 className="text-5xl text-center font-bold font-heading tracking-px-n leading-tight md:max-w-xl mx-auto">
                                {"Recibimos tu solicitud"}
                            </h2>
                            <h3 className="pt-10 text-base text-center leading-normal md:max-w-sm mx-auto">
                                {"Nos contactaremos contigo a la brevedad"}
                            </h3>
                            <a className="text-blue-600 hover:text-blue-800 pt-4 flex justify-center" href="/landing/index.html">Volver a la página de inicio</a>
                        </div>
                    </div>
                ) : (
                    <div className="relative z-10 container px-4 mx-auto">
                        <div>
                            <p className="pb-6 mb-6 text-xm text-blue-500 text-center font-semibold uppercase tracking-px">
                                {"Tu periodo de prueba aún no termina"}
                            </p>
                            <h2 className="pb-10 text-5xl text-center font-bold font-heading tracking-px-n leading-tight md:max-w-xl mx-auto">
                                ¿Quieres que nos contactemos contigo?
                            </h2>
                            <h3 className="pt-4 text-base text-center leading-normal md:max-w-sm mx-auto">
                                Te quedan{" "}
                                <strong className="font-bold">
                                    {formatDays(userAccount.remainingTrialDays)}
                                </strong> de prueba gratis
                            </h3>
                        </div>
                        <div className="py-2 flex flex-col justify-center pt-8">
                            <button
                                onClick={() => submitForm()}
                                className="px-4 py-2 mx-auto text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
                            >
                                Si, contáctenme
                            </button>
                            <button
                                onClick={() => declineForm()}
                                className="underline text-blue-600 hover:text-blue-800 pt-4"
                            >
                                Volver
                            </button>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
}

export default Subscribe;
