import { useState, useRef, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";

// STORE
import { useDispatch, useSelector } from "react-redux";

// HOOKS
import { Fetch, useFetch } from "../../hooks/useFetch";

// COMPONENTS
import {
  AccountBodyBox,
  AccountBoxHeading,
  AccountBoxContent,
  AccountBoxInfo,
} from "../../components/ui/accounts/AccountComponents";
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner";
import {
  displayString,
  getContrastColour,
} from "../../components/ui/utilities/functions";
import AccountPasswordChange from "../../components/ui/accounts/AccountPasswordChange.js";
import ColorPicker from "../AdminPage/ColorPicker";
import useColorCompany from "../../hooks/useColorCompany";
import { showNotifications } from "../../store/notificationsSlice";
import { updateUser } from "../../store/userSlice";
import ImageUpload from "./ImageUpload";
import AccountColorSchemeToggle from "./AccountColorSchemeToggle";
import useColorScheme from "../../hooks/useColorScheme";
import { updateAnalysisDataConfig } from "../../store/analysisDataConfigSlice";
import FirebaseContext from "../../firebase/FirebaseContext.js";
// SENTRY
import * as Sentry from "@sentry/browser";

function AccountPage() {
  // CONTEXT
  const firebase = useContext(FirebaseContext);
  // STORE
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const urls = useSelector((state) => state.urls);
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  );
  // HOOKS
  const [params] = useSearchParams();

  const { defaultColors, getColorPortfolioScheme } = useColorCompany();
  const [colorScheme] = useColorScheme();
  // REF
  const UIDRef = useRef(params.get("uid") || user?.uid);
  // STATE
  const [userData, setUserData] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [changeToggle, setChangeToggle] = useState({
    colors: colorScheme,
    logo: colorScheme,
  });
  const [openColorPicker, setOpenColorPicker] = useState({
    portfolioA: false,
    portfolioB: false,
  });
  const [colorSelected, setColorSelected] = useState({
    portfolioA: getColorPortfolioScheme({
      portfolio: "portfolioA",
      scheme: colorScheme,
    }),
    portfolioB: getColorPortfolioScheme({
      portfolio: "portfolioB",
      scheme: colorScheme,
    }),
  });

  useEffect(() => {
    setColorSelected({
      portfolioA: getColorPortfolioScheme({
        portfolio: "portfolioA",
        scheme: changeToggle.colors,
      }),
      portfolioB: getColorPortfolioScheme({
        portfolio: "portfolioB",
        scheme: changeToggle.colors,
      }),
    });
  }, [changeToggle.colors, colorScheme, getColorPortfolioScheme]);

  useEffect(() => {
    setColorSelected({
      portfolioA: getColorPortfolioScheme({
        portfolio: "portfolioA",
        scheme: colorScheme,
      }),
      portfolioB: getColorPortfolioScheme({
        portfolio: "portfolioB",
        scheme: colorScheme,
      }),
    });
  }, [colorScheme]);

  // LIFE CYCLE
  useFetch(
    `${urls.API_URL}/advisors/${UIDRef.current}/get`,
    {},
    (response) => {
      const { data } = response;
      setUserData(data);
    },
    [setUserData],
  );

  // MISC
  const getMainTitle = () =>
    user?.uid === userData.uid
      ? "Mi Perfil"
      : `Perfil de ${userData.name} ${userData.lastname}`;

  const handleColorSelected = ({ namePortfolio, color }) => {
    setColorSelected({ ...colorSelected, [namePortfolio]: color });
  };

  const handleUpdateUserData = async (reestar = false) => {
    setDisableButton(true);
    let dataUpdate = null;
    if (reestar) {
      dataUpdate = { colors: defaultColors };
      setColorSelected({
        portfolioA: defaultColors[0],
        portfolioB: defaultColors[1],
      });
    } else if (!user?.colors?.dark && !user?.colors?.light) {
      dataUpdate = {
        colors: {
          [changeToggle.colors]: [
            colorSelected.portfolioA,
            colorSelected.portfolioB,
          ],
        },
      };
    } else {
      dataUpdate = {
        colors: {
          ...user.colors,
          [changeToggle.colors]: [
            colorSelected.portfolioA,
            colorSelected.portfolioB,
          ],
        },
      };
    }
    try {
      Fetch(
        `${urls.API_URL}/advisors/${UIDRef.current}/update_colors`,
        dataUpdate,
        { firebase },
        (data) => {
          if (data.status === "OK") {
            setDisableButton(false);
            dispatch(
              showNotifications({
                show: true,
                status: "success",
                title: "Colores guardados",
                message:
                  "Ya puedes visualizar los nuevos colores desde tus análisis",
                autoHide: true,
                time: 3500,
              }),
            );
            dispatch(updateUser({ ...user, colors: dataUpdate.colors }));
            dispatch(
              updateAnalysisDataConfig({
                ...analysisDataConfig,
                colors: dataUpdate.colors,
              }),
            );
          } else {
            setDisableButton(false);
            dispatch(
              showNotifications({
                show: true,
                status: "warning",
                title: "Encontramos un error",
                message:
                  "Por favor inténtelo nuevamente o contáctese con nosotros.",
                autoHide: true,
                time: 3500,
              }),
            );
          }
        },
      );
    } catch (error) {
      Sentry.captureException(error);
      setDisableButton(false);
      dispatch(
        showNotifications({
          show: true,
          status: "warning",
          title: "Encontramos un error",
          message: "Por favor inténtelo nuevamente o contáctese con nosotros.",
          autoHide: true,
          time: 3500,
        }),
      );
    }
  };

  const handleChangeToggleColors = (value) => {
    setChangeToggle({ ...changeToggle, colors: value });
    setColorSelected({
      portfolioA: getColorPortfolioScheme({
        portfolio: "portfolioA",
        scheme: value,
      }),
      portfolioB: getColorPortfolioScheme({
        portfolio: "portfolioB",
        scheme: value,
      }),
    });
  };
  const handleChangeToggleLogo = (value) => {
    setChangeToggle({ ...changeToggle, logo: value });
  };

  return (
    <main className="px-8 py-20 min-h-full  bg-white dark:bg-slate-900">
      {!userData ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-1/2 mx-auto mb-5 grid grid-cols-[1fr,_140px] items-center gap-4">
            <div className="flex items-center gap-2">
              <h1 className="text-base">{getMainTitle()}</h1>
            </div>
          </div>

          <div className="w-1/2 mx-auto flex flex-col gap-5 justify-start items-start">
            <AccountBodyBox>
              <AccountBoxHeading>
                <div className="flex items-start justify-between gap-2">
                  <div>
                    <h3 className="text-base font-light">
                      Información personal
                    </h3>
                    <p className="text-sm text-slate-500 dark:text-slate-400">
                      Información básica de tu cuenta
                    </p>
                  </div>
                  {/* <button
                    type="button"
                    className="shrink-0 w-20 py-1 px-2 text-xxs rounded-md border bg-blue-500 text-slate-50 dark:text-slate-900 hover:bg-blue-400"
                    onClick={() =>
                      navigate({
                        pathname: ROUTES.EDIT_USER,
                        search: createSearchParams({
                          uid: user.uid,
                        }).toString(),
                      })
                    }
                  >
                    Editar perfil
                  </button> */}
                </div>
              </AccountBoxHeading>

              <AccountBoxContent>
                <AccountBoxInfo>
                  <span className="py-2 text-xs text-slate-500 dark:text-slate-400">
                    Nombre
                  </span>
                  <p className="py-2 text-sm">{`${userData.name} ${userData.lastname}`}</p>
                </AccountBoxInfo>
                <AccountBoxInfo>
                  <span className="py-2 text-xs text-slate-500 dark:text-slate-400">
                    Correo electrónico
                  </span>
                  <p className="py-2 text-sm">{user?.email}</p>
                </AccountBoxInfo>
                <AccountBoxInfo>
                  <span className="py-2 text-xs text-slate-500 dark:text-slate-400">
                    Compañía
                  </span>
                  <p className="py-2 text-sm">
                    {displayString(userData?.company.name)}
                  </p>
                </AccountBoxInfo>
              </AccountBoxContent>
            </AccountBodyBox>

            <AccountBodyBox>
              <AccountBoxHeading>
                <div className="flex justify-between">
                  <h3 className="text-base">Paleta de colores</h3>
                  <AccountColorSchemeToggle
                    setChangeToggle={handleChangeToggleColors}
                  />
                </div>
                <p className="text-sm text-slate-500 dark:text-slate-400">
                  Configura los colores que tus clientes verán en sus
                  propuestas.
                </p>
              </AccountBoxHeading>

              <AccountBoxContent>
                <AccountBoxInfo>
                  <span className="self-start text-xs text-slate-500 dark:text-slate-400">
                    Pincha en los botones para seleccionar un color
                  </span>
                  <div className="self-start flex justify-between gap-5">
                    <div className="flex items-center gap-4">
                      <div>
                        <button
                          className="px-3 py-2 rounded-md text-xs"
                          style={{
                            backgroundColor: `${colorSelected?.portfolioA}`,
                            color: getContrastColour(colorSelected?.portfolioA),
                          }}
                          onClick={() =>
                            setOpenColorPicker({ portfolioA: true })
                          }
                        >
                          Portafolio A
                        </button>

                        <ColorPicker
                          namePortfolio="portfolioA"
                          handleColorSelected={handleColorSelected}
                          openColorPicker={openColorPicker.portfolioA}
                          setOpenColorPicker={setOpenColorPicker}
                          baseColor={colorSelected?.portfolioA}
                          changeToggle={changeToggle}
                        />
                      </div>
                      <div>
                        <button
                          className="px-3 py-2 rounded-md text-xs"
                          style={{
                            backgroundColor: `${colorSelected?.portfolioB}`,
                            color: getContrastColour(colorSelected?.portfolioB),
                          }}
                          onClick={() =>
                            setOpenColorPicker({ portfolioB: true })
                          }
                        >
                          Portafolio B
                        </button>

                        <ColorPicker
                          namePortfolio="portfolioB"
                          handleColorSelected={handleColorSelected}
                          openColorPicker={openColorPicker.portfolioB}
                          setOpenColorPicker={setOpenColorPicker}
                          baseColor={colorSelected?.portfolioB}
                          changeToggle={changeToggle?.logo}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={() => {
                        handleUpdateUserData();
                      }}
                      disabled={disableButton}
                      className="h-8 shrink-0 w-20 py-1 px-2 text-xxs rounded-md border bg-blue-500 text-slate-50 dark:text-slate-900 hover:bg-blue-400 disabled:opacity-50 disabled:bg-slate-500 disabled:text-slate-300 disabled:border-slate-700 disabled:cursor-default
                      hover:disabled:bg-slate-600 hover:disabled:text-slate-400"
                    >
                      Guardar
                    </button>
                  </div>
                </AccountBoxInfo>
              </AccountBoxContent>
            </AccountBodyBox>
            <AccountBodyBox>
              <AccountBoxHeading>
                <div className="flex justify-between">
                  <h3 className="text-base">Logotipo</h3>
                  <AccountColorSchemeToggle
                    setChangeToggle={handleChangeToggleLogo}
                  />
                </div>
                <p className="text-sm text-slate-500 dark:text-slate-400">
                  Incluye tu logotipo en las propuestas y análisis
                </p>
              </AccountBoxHeading>

              <AccountBoxContent>
                <AccountBoxInfo>
                  <div className="flex items-center gap-4 w-full col-span-2 ">
                    <ImageUpload
                      uid={user.uid}
                      nameLogo="light"
                      changeToggle={changeToggle.logo}
                    />
                    {/* <ImageUpload uid={user.uid} nameLogo="dark" /> */}
                  </div>
                </AccountBoxInfo>
              </AccountBoxContent>
            </AccountBodyBox>

            <AccountBodyBox>
              <AccountBoxHeading>
                <h3 className="text-base font-light">Cambiar contraseña</h3>
                <p className="text-sm text-slate-500 dark:text-slate-400">
                  Llena los siguientes campos con tu nueva contraseña para
                  cambiarla.
                </p>
              </AccountBoxHeading>

              <div className="mb-5 border-b border-slate-600 last:border-0">
                <AccountPasswordChange />
              </div>
            </AccountBodyBox>
          </div>
        </>
      )}
    </main>
  );
}

export default AccountPage;
